.Case_banner_main_wrapp{
    width: 100%;
    height: 100vh;
    margin: auto;
    position: relative;
    display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 92px;
}
.case_banner_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.case_banner_back_img{
    width: 100%;
    height: auto; /* Ensures aspect ratio is maintained */
  }
  
  /* Case banner base wrapper */
  .case_banner_base_wrapp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    border-radius: 5px;
    text-align: -webkit-center;
    width: 90%;
  }
  .case_banner_logo_img{
    width: 142px;
    height: 43px;
  }
  .case_banner_logo_img_dustit{
    max-width: 250px;
  }
  .case_banner_base_wrapp h1 {
    font-family: var(--font-gill);
    font-size: 60px;
    font-weight: 400;
    line-height: 78px;
    letter-spacing: -1.8198258876800537px;
    text-align: center;
    background: linear-gradient(90deg, rgba(92, 184, 236, 0.7) 0%, #5864F9 100%);
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent; 
  }
  
  .case_banner_base_wrapp h2 {
    font-family: var(--font-aeoniktrial)!important;
    font-size: 60px;
    font-weight: 400;
    line-height: 78px;
    letter-spacing: -1.8198258876800537px;
    text-align: center;
    color: var(--color-white);
  
  }
.case_banner_logo_img_beurer{
  width: 142px;
  /* height: 43px; */
}

  @media (max-width: 767px) {
    .Case_banner_main_wrapp {
      height: 71vh;
  }
  .case_banner_back_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .case_banner_logo_img{
    width: 142px;
    height: 43px;
  }
  .case_banner_base_wrapp h1 {
    font-size: 40px;
  }
  .case_banner_base_wrapp h2 {
    font-size: 45px;
    line-height: 54px;
  }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .Case_banner_main_wrapp {
      width: 100%;
      height: 73vh;

  }
  .case_banner_back_img {
    height: 100%;
    object-fit: cover;
  }
}