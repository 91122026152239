@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Font100"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gelion_Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font300"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gelion\ Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font400"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gelion\ Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font500"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gelion\ Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font600"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gelion\ SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font700"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gelion\ Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontexo"; /* Define a name for the font */
  src: url("/src/utils/fonts/Exo2-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontinter"; /* Define a name for the font */
  src: url("/src/utils/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontpoppins"; /* Define a name for the font */
  src: url("/src/utils/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontgill"; /* Define a name for the font */
  src: url("/src/utils/fonts/Gill\ Sans.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontrubik"; /* Define a name for the font */
  src: url("/src/utils/fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontopensans"; /* Define a name for the font */
  src: url("/src/utils/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fontaeoniktrial"; /* Define a name for the font */
  src: url("/src/utils/fonts/aeoniktrial-regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fontgotham"; /* Define a name for the font */
  src: url("/src/utils/fonts/gothamrnd_medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Fontcarossoft"; /* Define a name for the font */
  src: url("/src/utils/fonts/CarosSoftExtraBold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-base: "Font400", sans-serif;
  --font-100: "Font100", sans-serif;
  --font-300: "Font300", sans-serif;
  --font-400: "Font400", sans-serif;
  --font-500: "Font500", sans-serif;
  --font-600: "Font600", sans-serif;
  --font-700: "Font700", sans-serif;

  --font-exo: "Fontexo", sans-serif;
  --font-inter: "Fontinter", sans-serif;
  --font-poppins: "Fontpoppins", sans-serif;
  --font-gill: "Fontgill", sans-serif;
  --font-rubik: "Fontrubik", sans-serif;
  --font-opensans: "Fontopensans", sans-serif;
  --font-aeoniktrial: "Fontaeoniktrial", sans-serif;

  --font-gotham: "Fontgotham", sans-serif;
  --font-caros: "Fontcarossoft", sans-serif;

  /* font sizes start*/
  --font-14: 14px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-22: 22px;
  --font-24: 24px;
  --font-60: 60px;
  /* font sizes end */

  --color-ocher: #9f8e7a;
  --color-ocher-light: #9f8e7a8c;
  --color-brown: #2b1e16;
  --color-red: #ed1c24;
  --color-orange: #ff5e14;
  --color-black: #000;
  --color-black-light: #0000008c;
  --color-white: #ffffff;
  --color-gray: #898989;
  --color-gray-light: #9f9f9f;
  --color-dark-gray: #3f3f3f;
  --max-width: 1280px;
  --max-width-md: 940px;
  --max-width-sm: 420px;
  --fixed-width: 620px;
  --number-step: rgba(26, 42, 52, 0.25);
  --color-gradiant-1: linear-gradient(90deg, #5691e9 0%, #00a0a7 100%);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-gill)!important;
  /* font-family: "Lato", sans-serif !important; */
}

.container {
  /* max-width: var(--max-width); */
  max-inline-size: var(--max-width);
  margin-inline: auto;
  margin: 0 auto;
  width: 100%;
  /* max-width: 100vw; */
  padding: 0px 10px;
}

.container_2 {
  /* max-width: var(--max-width); */
  max-inline-size: 95%;
  margin-inline: auto;
  margin: 0 auto;
  width: 100%;
  /* max-width: 100vw; */
  padding: 0px 10px;
}

.main_wrapp {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  overflow-x: hidden;
}

.title_text {
  font-family: var(--font-base);
  font-size: 60px;
  font-weight: 200;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
  word-break: keep-all;
}

.title_text_bold {
  font-family: var(--font-base);
  font-size: 60px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
  word-break: keep-all;
}

.des_text {
  font-family: var(--font-base);
  font-size: 18px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-gray);
  word-break: keep-all;
}

body {
  font-family: var(--font-base);
  background: var(--color-bg);
  overflow-x: hidden;
  background-color: black;
  color: white;
}

figure {
  margin: 0px !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: sans-serif; */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;
  text-decoration: none;
}

.underline {
  text-decoration: underline !important;
}

.underline:hover {
  text-decoration: none !important;
}

strong,
b {
  font-weight: bold;
}

i {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.hr {
  width: 100%;
  height: 2px;
  background: var(--color-light-gray);
  margin: 1rem auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="checkbox"] {
  transform: scale(1.3);
}

/* ::-moz-selection {
  text-shadow: none;
}

::selection {
  text-shadow: none;
} */

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

button[type="submit"] {
  cursor: pointer;
  border: none;
}

button:disabled {
  cursor: not-allowed;
  opacity: 80%;
}

textarea {
  resize: none;
}

/* 
img {
  max-width: 100%;
  height: auto;
} */

a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

/* texts */

.h1 {
  font-size: 50px;
  font-weight: 900;
  font-family: var(--font-base);
  line-height: 130%;
}

.h2 {
  font-size: 45px;
  font-weight: 800;
  font-family: var(--font-base);
}

.h3 {
  font-size: 40px;
  font-weight: 800;
  font-family: var(--font-base);
}

.h4 {
  font-size: 30px;
  font-weight: 500;
  font-family: var(--font-base);
}

.h5 {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-base);
}

p {
  font-family: var(--font-base);
  /* font-weight: 400; */
  /* font-size: 1rem; */
  /* margin-top: 0; */
  /* margin-bottom: 0.3em; */
}

.fs-des {
  font-size: 14px;
  font-family: var(--font-base);
  font-weight: 300;
}

.fs-18 {
  font-size: 20px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 20px;
}

.f-b900 {
  font-weight: 900;
}

.f-bold {
  font-weight: bold;
}

/* margines start */

.mb-1 {
  margin-bottom: 1rem;
}

.mb-1_5 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

/* margines end */

/* sections css */

.section_main_wrapp {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 100vw;
  overflow: hidden;
}

.section_inner_wrapp {
  width: 100%;
  max-width: var(--max-width);
}

.sec_light_heading {
  font-family: var(--font-base);
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  /* text-align: left; */
  color: var(--color-ocher-light);

  text-transform: uppercase;
}

.sec_heading {
  font-family: var(--font-700);
  font-size: var(--font-56);
  font-weight: 500;
  line-height: 78px;
  text-align: left;
}

.sec_description {
  font-family: var(--font-400);
  font-size: var(--font-24);
  font-weight: 400;
  text-align: left;

  color: var(--color-black);
}

/* button start */

.btn-gradiant {
  align-items: center;
  background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
  border: 0;
  border-radius: 28px;
  box-shadow: rgba(130, 169, 169, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: var(--font-300);
  font-weight: var(--font-300);
  font-size: var(--font-16);
  justify-content: center;
  max-width: 100%;
  padding: 1px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
select{
cursor: pointer;
}
.btn-gradiant:active,
.btn-gradiant:hover {
  outline: 0;
}

.btn-gradiant span {
  background-color: rgb(0, 0, 0);
  padding: 8px 16px;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  transition: 0.5s all;
}

.btn-gradiant:hover span {
  background: none;
}

.btn-gradiant:active {
  transform: scale(0.9);
}

/* button end */
.btn_home {
  width: 59px;
  transition-duration: 500ms;
  height: 59px;
  border: 1px solid grey;
}

.home_arrow > img{
  WIDTH: 18px;
    margin: auto;
    align-items: center;
    position: relative;
    top: 17px;
    z-index: 10;
}
.service_arrow{
  background-color: #F5F5F5;
}
.service_arrow > img{
  WIDTH: 18px;
    margin: auto;
    align-items: center;
    position: relative;
    top: 17px;
    z-index: 10;
}
.btn_ready {
  width: 374px;
  transition-duration: 500ms;
  height: 96px;
  border: 4.5px solid;
  border: 1px solid grey;
  margin-top: 2rem;
}
.btn_blog {
  width: 374px;
  transition-duration: 500ms;
  height: 96px;
  border: 4px solid grey;
}
.btn_story {
  width: 177px;
  transition-duration: 500ms;
  height: 51px;
  border: 1px solid grey;
}
.ready_arrow{
  color: var(--color-white);
  font-size: 25px;
  font-family: var(--font-400);
  margin: auto;
  text-align: center;
  padding-top: 29px;
}
.about_arrow{
  color: var(--color-white);
  font-size: 27px;
  font-family: var(--font-400);
  margin: auto;
  text-align: center;
  padding-top: 29px;
}
.story_arrow{
  font-family: var(--font-400);
  font-size: var(--font-18);
  font-weight: 400;
  text-align: center;
  background-color: #1C1D22;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 10px 1rem;
}
.btn_service{
  width: 58px;
  transition-duration: 500ms;
  height: 58px;
  border: 1px solid white;
  position: absolute;
  right: 13px;
  top: 22px;
  background-color: rgb(255 254 254);
}
.border-none{
  border: none;
}
@media (max-width: 786px){
  .story_arrow{
    padding: 10px 1rem;
    
  }
  .ready_arrow{
    padding-top: 12px;
  }
  .btn_blog {
    width: 304px;
    transition-duration: 500ms;
    height: 67px;
    border: 1px solid grey;
}
.about_arrow{
  font-size: 18px;
  padding-top: 15px;
}
}
