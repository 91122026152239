.CO_main_wrapp {
  width: 100%;
  margin: auto;
  background-color: var(--color-white);
position: relative;
  padding: 5rem 0 2.2rem 0;
}

.CO_base_wrapp {
  max-width: var(--max-width);
  margin: auto;
}

.CO_base_wrapp>h1 {
  font-size: 48px;
  color: var(--color-black);
  line-height: 90px;
  text-align: center;
}

.CO_base_wrapp>p {
  font-size: 20px;
  color: var(--color-black);
  text-align: center;
}

.CO_accordian {
  padding: 2rem 0 0 0;
}

.CO_accordian_div {
  border-top: 1px solid #000000b5;
  overflow: hidden;
  color: var(--color-black);
}

.CO_accordian_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  cursor: pointer;
  width: 100%;
}

.CO_accordian_header>p {
  font-size: 20px;
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 600;
  width: 98%;
}

.CO_accordian_header button {
  position: relative;
  width: 2%;
  display: flex;
  justify-content: end;
}

.CO_accordian_inner {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #80808061;
  padding: 1rem 0;

}

.CO_accordian_inner:last-child {
  border-bottom: none;
}

.CO_acco_text {
  width: 50%;
  text-align: start;
  cursor: pointer;
}

.CO_acco_exp {
  width: 30%;
  text-align: start;
}

.CO_acco_btn {
  width: 20%;
  text-align: -webkit-right;
}

.CO_acco_btn>button {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  font-size: 17px;
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.CO_accordian_inner>p {
  font-size: 17px;
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 400;
}


@media (max-width: 767px) {
  .CO_base_wrapp{
    width: 90%;
  }
  .CO_base_wrapp>h1 {
    font-size: 35px;
    line-height: normal;
    padding-bottom: 1rem;
  }

  .CO_accordian_inner {
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  .CO_acco_text,
  .CO_acco_exp,
  .CO_acco_btn {
    width: 100%;
    text-align: start;
  }

  .CO_accordian {
    padding: 2rem 0 0 0;
    margin: auto;
  }

  .CO_acco_btn>button {
    padding: 5px 0;
    gap: 9px;
  }
  .CO_accordian_header button {
    width: 4%;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
.CO_base_wrapp {
  width: 90%;
}
}