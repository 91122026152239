.sub_blog_main{
    width: 100%;
    margin: auto;
    max-width: var(--max-width);
    /* text-align: center; */
    margin-top: 150px;
    margin-bottom: 92px;
}
.sub_blog_main>h1{
    font-size: 65px;
    font-family: var(--font-aeoniktrial)!important;
    line-height: 90px;
    letter-spacing: -1.82px;
}
.dat{
    font-size: 22px;
    font-family: var(--font-400);
    margin-top: 10px;
    margin-bottom: 53px; 
    letter-spacing: -1.82px;
}
.sub_blog_main>p{
    font-size: 19px;
    font-family: var(--font-100); 
    margin: 34px 0px 42px 0px;
    text-align: justify;
    line-height: 22.79px;
}
.sub_blog_main>img{
    width: 100%;
    margin-bottom: 60px;
    height: 640px;
    object-fit: cover;
    
}
.head{
    font-size: 27px;
    font-family: var(--font-500);
    margin-top: 10px; 
}
.sub_blog_main>h2{
    font-size: 59px;
    font-family: var(--font-500);
    margin-top: 75px;
}

.sub_blog_content_div >ul{
    list-style-type:decimal;
}
.sub_blog_content_div >ul>li>span{
    font-size: 18px;
    font-family: var(--font-500);
    margin-top: 10px; 
    font-weight: 600;
}
.pb5{
    padding-bottom: 5px;
}
.sub_b_li_des{
    margin: 17px 0;
}
.sub_b_li_des >h3{
    font-size: 18px;
    font-family: var(--font-500);
    margin-top: 15px; 
    font-weight: 600;
}
.sub_b_li_des >ul{
    list-style-type: disc; 
    padding: 10px 0 10px 20px;
}
.sub_b_li_des >ul>li{
    padding-bottom: 7px;
}
.sub_b_li_des >ul >li >span{
    font-weight: 600;
}
.sub_b_li_des1 >h3{
    font-size: 18px;
    font-family: var(--font-500);
    margin: 10px 0; 
    font-weight: 600;
}
.sub_b_li_des_num{
    margin: 10px 0;
}
.sub_b_li_des_num >h3{
    font-size: 18px;
    font-family: var(--font-500);
    margin-top: 10px; 
    font-weight: 600;
}
.sub_b_li_des_num >ul{
    list-style-type: decimal; 
    padding: 10px 0 10px 20px;
}
.sub_b_li_des_num >ul >li >span{
    font-weight: 600;
}
@media(max-width: 767px){
    .sub_blog_main{
        width: 90%;
    }
    .sub_blog_main>h1 {
        font-size: 29px;
        font-family: var(--font-500);
        line-height: 40px;
    }
    .sub_blog_main>h2 {
        font-size: 29px;
        font-family: var(--font-500);
        margin-top: 41px;
    }
    .sub_blog_main>img {
        width: 100%;
        margin-bottom: 44px;
        height: auto;
    }
    .head {
        line-height: 26px;
    }
    .sub_b_li_des {

        text-align: justify;
    }
}
@media (min-width: 768px) and (max-width:1024px){
    .md\:-left-20 {
        left: 0;
    }
    .sub_blog_main{
        width: 90%;
    }
    .sub_blog_main>h1 {
        font-size: 56px;
        line-height: 70px;
    }
}