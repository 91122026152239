.cs_ds_main_wrapp{
    width: 100%;
    margin: auto;
    padding: 5rem 0;
}
.cs_ds_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.cs_ds_base_wrapp >h1{
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
    color: var(--color-white);
    margin-left: 4%;
}
.cs_ds_base_wrapp >h1>span{
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;
    color: var(--color-white);  
}
.cs_ds_content_div{
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    margin: 3rem 4% auto 4%;
}
.cs_ds_flexrow1{
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    align-items: center;
}
.cs_50{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cs_25{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cs_25_n{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.cs_40{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cs_20{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* usa button box styles css start */
/* newsletter */
.about_nl_input_wrapp {
    width: 100%;
    padding: 6px;
    border-radius: 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.news-form {
    width: 100%;
}

.news_letter_form {
    display: flex;
    width: 100%;
}.about_nl_input_box_wrapp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 83%;
    margin-left: 10px;
}
.about_nl_input_box_wrapp > img {
    width: 1.2rem;
    height: 1.2rem;
}
.about_nl_input_box_wrapp > input {
    width: 80%;
    border: none;
    font-size: 16px;
}
.about_nl_submit_btn {
    background-color: #1ec199;;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    color: #fff;
    font-family: var(--font-base);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
    pointer-events: none;
}

/* news letter end */
.apply-trans-btn-usa{
    border-radius: 35.764px;
    border: 1.022px solid #1ec199;
    color: #1ec199;
    text-align: center;
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.619px;
    letter-spacing: 1.533px;
    width: 238px;
    height: 52px;
    background: transparent;
    pointer-events: none;
}
.inquiry-btn-usa{
    border-radius: 35.764px;
    border: 1.022px solid #1ec199;
    background: #1ec199;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.619px;
    letter-spacing: 1.533px;
    width: 238px;
    height: 52px;
    pointer-events: none;
}
.apply-btn-usa {
    border-radius: 15px;
    background: rgba(30, 193, 153, 0.63);
    -webkit-backdrop-filter: blur(14.5px);
    backdrop-filter: blur(14.5px);
    width: 280px;
    border: none;
    height: 72px;
    color: #FFF;
    font-family: var(--font-base);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: box-shadow 0.3s ease-in-out;
    pointer-events: none;
}
.apply-blue-btn-usa{
    background-color: #02245b;
    font-size: 16px;
    font-family: var(--font-base);
    color: var(--color-white);
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    width: 160px;
    pointer-events: none;
}
.d-flex-group-35{
    display: flex;
    gap: 1rem;
    width: 35%;
}
/* dropdown */
.custom-dropdown {
    position: relative;
    width: 100px;
}
.dropdown-header {
    pointer-events: none;
    border: 2px solid #02245b;
    background-color: transparent;
    border-radius: 4px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.selected-country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.country-name {
    font-size: 14px;
    color: #02245b;
    font-weight: 600;
}
.country-logo {
    margin-right: 8px;
    width: 19px;
    height: 14px;
}
/* dropdown end */

/* join now in blog   */
.input-btn-div-usa {
    display: flex;
    width: 100%;
    pointer-events: none;
}
.join-form {
    width: 100%;
}
.input-btn-div-usa input {
    border-radius: 10px 0 0 10px;
    background: #fff;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
    color: rgba(0, 0, 0, 0.65);
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 75.5px;
    height: 57px;
    border: none;
    width: 60%;
    padding-left: 20px;
}
.input-btn-div-usa button {
    border-radius: 0px 10px 10px 0px;
    background: #02245b;
    color: #fff;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    height: 57px;
    border: none;
    width: 40%;
    pointer-events: none;
}

/* join now end */

/* contact start */
.contact-sec2-email-us {
    width: 100%;
    border-radius: 6px;
    border: 1.5px dashed #FF5E14;
    background:var(--color-white);
    padding: 13px;
    display: flex;
    flex-direction: row;
    flex-direction: row;
    align-items: center;
}
.contact-sec2-email-us .circle-us {
    width: 25px;
    border-radius: 25px;
    border: 2px solid #FF5E14;
    height: 25px;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-us img {
    width: 65%;
}
.email-us {
    color: #000;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.191px;
    text-transform: capitalize;
}
.content-us {
    color: #000;
    font-family: var(--font-base);
    font-size: 13.33px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.001px;
}

.contact-sec2-call-us {
    width: 100%;
    border-radius: 6px;
    border: 1.5px dashed #1EC199;
    background:var(--color-white);
    padding: 13px;
    display: flex;
    flex-direction: row;
    flex-direction: row;
    align-items: center;
}
.contact-sec2-call-us .circle-us {
    width: 25px;
    border-radius: 25px;
    border: 2px solid #1EC199;
    height: 25px;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-sec2-skype-us {
    width: 100%;
    border-radius: 6px;
    border: 1.5px dashed #02245B;
    background:var(--color-white);
    padding: 13px;
    display: flex;
    flex-direction: row;
    flex-direction: row;
    align-items: center;
}
.contact-sec2-skype-us .circle-us {
    width: 25px;
    border-radius: 25px;
    border: 2px solid #02245B;
    height: 25px;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* contact end */
/* end */

/* rarerez buttons start */
.next_btn_rarerez{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.next_btn_rar{
    max-width: 359px;
    height: 69px;
    width: 100%;
}
.rar_cancelbtn{
    /* border: 2px solid;
    border-image-source: linear-gradient(94.33deg, #FF004C 7.2%, rgba(202, 172, 255, 0.21) 97.82%); */
    background: linear-gradient(97.08deg, #D2003F 10.33%, rgba(202, 172, 255, 0.21) 95.72%);
    height: 50px;
    width: 130px;
    border-radius: 40px;
    box-shadow: 8px 8px 15px 0px #14151D;
    box-shadow: -6px -6px 15px 0px #303240;
    pointer-events: none;  
}
.rar_arrowbtn{
    height: 79px;
    width: 79px;
}
.rar_addmorelink{
    border: 1px solid;
    border-image-source: linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
    border-image-slice: 1;
    color: #FF004C;
    min-width: 358px;
    height: 50px;
    pointer-events: none;  
}
.next_btn_rarerez_bid{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.rar_bid_btn{
    width: 163px;
    height: 42px;
}
.next_btn_rarerez_wrong{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.next_btn_rarerez_wrong >button{
    height: 45px;
    width: 45px;
    border-radius: 45px;
    border: 1px solid;
    border-image-source: linear-gradient(116.08deg, rgba(255, 255, 255, 0.59) 11.67%, rgba(255, 0, 76, 0) 44.03%);
    box-shadow: 0px 1px 6px 0px #FF004CC2 inset;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;  
}
.rar_home_btn{
    height: 78px;
    max-width: 369px;
}
.rar_edit_btn{
    height: 53px;
    width: 53px;
}
.rar_next_btn_sm {
    border: 1px solid;
    border-image-source: linear-gradient(116.08deg, rgba(255, 255, 255, 0.59) 11.67%, rgba(255, 0, 76, 0) 44.03%);
    box-shadow: 
        0px 1px 12px 0px #FF004CC2 inset, /* First shadow */
        0px 4px 14px 0px #9A92D24D inset, /* Second shadow */
        0px 39px 99px -49px #CAACFF73 inset; /* Third shadow */
    background: #D9D9D903;
    width: 175px;
    height: 67px;
    border-radius: 41.5px;
    pointer-events: none;  
}
.rar_add_btn{
    border-image-source: linear-gradient(140.71deg, #FF004C 15.3%, rgba(255, 0, 76, 0) 89.21%);
    height: 67px;
    width: 67px;
    border-radius: 66px;
    border: 3.35px solid #FF004CC2 ;
    box-shadow: 0px 1px 6px 0px #FF004CC2 inset,
                7.82px 4.47px 22.35px 0px #FF004C5C inset,
                -8.94px -4.47px 25.71px 0px #CAACFF21 inset;
    font-size: 43px;   
    display: flex;
    justify-content: center;
    align-items: center;     
    pointer-events: none;    
}
.next_btn_rarerez_3rd{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
}
/* rarerez buttons end */
/* dust it start */
.cs_33{
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.side_btn_dust{
    width: 51px;
    height: 38px;
}
.btn_dust{
    width: 49px;
    height: 49px;
}
.sigle_btn_dust{
    width: 280px;
    height: 48px;
}
.play_btn_dust{
    pointer-events: none;
    background: #00A1A6;
    width: 280px;
    height: 48px;
    border-radius: 14px;
}
.passwd_dust{
    border: 0.28px solid #E2E2E2;
    pointer-events: none;
    width: 280px;
    height: 48px;
    border-radius: 14px;
    text-align: left;
    padding-left: 2rem;

}
.cs_ds_flexrow1_dust{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 2rem 3rem;
}
/* dust it end */

/* beurer */
.cs_ds_flexrow1_beurer {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 2rem 3rem;
    gap: 3rem;
}
.side_btn_beurer {
    width: 100%;
    /* height: 38px; */
}

@media(max-width: 767px){
    .cs_ds_base_wrapp {
        width: 90%;
    }
    .cs_ds_content_div {
        margin: 3rem auto auto auto;
    }
    .cs_ds_base_wrapp >h1 {
        margin-left: 0;
        font-size: 35.66px;
    }
    .cs_ds_flexrow1 {

        flex-direction: column;
    }
    .cs_50 {
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
    .cs_25_n {
        /* width: 50%; */
    }    
    .d-flex-group-35 {
        width: 100%;
        justify-content: center;
    }
    .cs_40 {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cs_40 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cs_20 {
        width: 50%;
    }
    .rar_add_btn {
        /* padding-top: 12px; */
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }
    .cs_ds_flexrow1_dust {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        padding: 0rem 3rem;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .cs_33{
        width: 100%;
    }

    .cs_ds_flexrow1_beurer {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        padding: 0rem 3rem;
        flex-wrap: wrap;
        gap: 2rem;
    }
}



@media (min-width: 768px) and (max-width: 1024px) {
.cs_ds_flexrow1 {
    flex-wrap: wrap;
}
.cs_50 {
    gap: 1rem;
}
.cs_ds_flexrow1_dust {
    flex-wrap: wrap;
    gap: 2rem;
}
.cs_40 {
    width: 100%;
    max-width: 368px;
}
}