
  .PS_Section1{
    position: relative;
    /* margin-bottom: 4rem; */
  }
  .PS_Sticky_div{
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
  }
  .PS_Motion_div{
    display: flex;
    gap: 1.5rem;
  }
  .PS_card_main_div{
    position: relative;

  }
  .H_project_img_div{
    border-radius: 9.8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    transition-property: transform;
    transition-duration: 300ms;
  }

  .contact_loc_title_div_h1{
    font-family: var(--font-gill);
    font-size: 115px;
    font-weight: 700;
    line-height:130px;
    text-align: left;
    
}
@media screen and (max-width: 768px) {
  .contact_loc_title_div_h1{
    font-family: var(--font-700);
    font-size: 60px;
    font-weight: 700;
    line-height:80px;
    text-align: left;  
    margin-left: 2rem;
  }
}