.service_question_main_wrapp {
    max-width: 100%;
    margin: auto;
    /* padding-top: 5rem; */
    position: relative;
    z-index: 3;
    background-color: black;
}

.service_question_base_wrapp {
    max-width: var(--max-width);
    margin: auto;
}

.service_question_base_wrapp > h2 {
    font-family: var(--font-poppins);
    font-size: 18.9px;
    font-weight: 500;
    line-height: 28.35px;
    text-align: center;
    padding-bottom: 1rem;
    color: #FFFFFF80;
}

.service_question_base_wrapp > h1 {
    font-family: var(--font-poppins);
    font-size: 37.79px;
    font-weight: 700;
    line-height: 68.03px;
    text-align: center;
}

.service_question_accordian_div {
    padding: 4rem 0;
}

.service_accordion_flex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.service_question_accordion_part {
    flex: 1;
}

.service_question_accordion {
    border-bottom: 0.94px solid #FFFFFF80;
    overflow: hidden;
    transition: all 0.3s ease;
}

.accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    padding-bottom: 1rem;
}

.accordion_header h2 {
    margin: 0;
    font-family: var(--font-poppins);
    font-size: 18.9px;
    font-weight: 500;
    line-height: 28.35px;
    text-align: left;
    color: #FFFFFF80;
}

.accordion_header h2:hover {
    color: #3b3bd3;
}

.accordion_header p {
    margin: 0;
    font-size: 18.9px;
    color: #3b3bd3;
    font-family: var(--font-poppins);
}

.accordion_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.accordion_content p {
    margin: 0;
    font-size: 15px;
    color: #fff;
    font-family: var(--font-poppins);
}

.service_question_accordion.active .accordion_content {
    max-height: 500px; /* Adjust this value based on your content */
}

.s_a_list {
    margin-top: 1rem;
    font-size: 15px;
    color: #FFFFFF80;
    font-family: var(--font-poppins);
}

.s_a_list ul {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 0;
    color: white;
}

.s_a_list li {
    margin-bottom: 5px;
}

@media(max-width: 767px) {
    .service_question_main_wrapp {
        max-width: 100%;
        padding: 4rem 0;
    }

    .service_accordion_flex {
        flex-direction: column;
    }

    .service_question_base_wrapp > h1 {
        line-height: 49.03px;
        text-align: center;
        font-size: 32px;
        max-width: 90%;
        margin: auto;

    }

    .service_question_accordian_div {
        padding: 2rem 0;
        width: 90%;
        margin: auto;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {

    .service_question_base_wrapp {
        width: 90%;
    }

}    