.Design_banner_main_wrapp {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  margin-top: 92px;
}
.design_blueimg_div{
  max-width: var(--max-width);
  margin: auto;
  position: relative;
}
.d_black_overlay {
  position: absolute;
  top: -92px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black); /* Black with 50% opacity */
  transition: transform 1s ease; /* Smooth transition for scaling */
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .design_content_div{

  }
  .design_content_div >h1{
      font-family: var(--font-inter);
      font-size: 107.07px;
      font-weight: 800;
      line-height: 129.58px;
      text-align: center;        
  }
  .design_content_div > p{
      font-family: var(--font-inter);
      font-size: 13.6px;
      font-weight: 500;
      line-height: 16.47px;
      text-align: center;
          
  }
.d_black_overlay.zoomed {
  /* transform: scale(0.7);  */
  transform: scaleX(0.8) scaleY(0.7);
  top: -69px;
  height: 92%;

}



.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}


.border {
  border: 2px solid black;
}









/* sticky */
.main_wrapp_bd {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  overflow-x: hidden;
}
.bd_main_wrapp {
  width: var(--max-width);
  /* height: 80vh; */
  height: 23vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--color-black); */
  position: relative;
  color: #ffff;

}
.bd-parallexLayer{
  height: 432!important;
}

.bd_base_wrapp::-webkit-scrollbar {
  display: none;
}
.bd_base_wrapp {
  width: var(--max-width);
  /* margin: 2rem auto; */
  height: 100%;
}




.sticky {
  margin-left: 15%;
  /* background-color: #ff6d6d; */
  /* width: 100%; */
  /* background-color: #000; */
}

.sticky-div-dream{
  width: 616px;
  background-color: #000;
  display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 75px;
}


.nonsticky-dream{
    /* width: 50%; */
    /* height: 500px; */
    height: 269px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px;
}
.nonsticky-number{
  font-size: 107px;
  font-weight:800;
  line-height: 199.4px;
  font-family:var(--font-inter);
}
.bd-parallexLayer-non{
  justify-content: center!important;
  border: none!important;
}
.stickyp{
  font-family: var(--font-inter);
  font-size: 13.6px;
  font-weight: 500;
  line-height: 16.47px;
  text-align: center;
  color: var(--color-white);
  padding: 0 20px;
}
.Design_banner_main_wrapp_mob{
  display: none;
}

@media (max-width: 768px) {
  .Design_banner_main_wrapp {
    display: none;
    }
.Design_banner_main_wrapp_mob{
  display: block;
  height: 70vh;
  display: flex;
  margin-top: 81px;

}
.Design_banner_base_wrapp_mob{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
        margin: auto;
}
.Design_banner_base_wrapp_mob >h1{
  font-size: 55px;
  line-height: 60px;
  font-weight: 800;
}
.Design_banner_base_wrapp_mob >p{
  font-size: 15.6px;
  font-weight: 500;
  line-height: 18.47px;
  text-align: center;
  margin-top: 2rem;
}

}
@media (min-width: 769px) and (max-width:1160px){
  .Design_banner_main_wrapp_mob{
    display: block;
    height: 60vh;
    display: flex;
    margin-top: 81px;
  
  }
  .Design_banner_base_wrapp_mob{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
          margin: auto;
  }
  .Design_banner_base_wrapp_mob >h1{
    font-size: 55px;
    line-height: 60px;
    font-weight: 800;
  }
  .Design_banner_base_wrapp_mob >p{
    font-size: 15.6px;
    font-weight: 500;
    line-height: 18.47px;
    text-align: center;
    margin-top: 2rem;
  }
  .Design_banner_main_wrapp {
display: none;
}
}