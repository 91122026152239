.contact_method_main_wrapp{
    width: 100%;
    margin: 5rem auto 0 auto;
    background: var(--color-white);
    
}
.contact_method_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
    padding: 5rem 0 4.5rem 0;
}
.contact_method_title_div >h1{
    font-family: var(--font-700);
    font-size: 59px;
    font-weight: 700;
    line-height: 78px;
    text-align: left;
    color: var(--color-black);
    max-width: 741px;

}
.grey-text{
    color: #AEAEAE;

}
.contact_method_detail{
    padding: 6rem 0 1rem 0;
    display: flex;
    gap: 9rem;
    align-items: baseline;
    justify-content: end;
}
.c_m_link{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}
.c_m_link >a{
    font-family: var(--font-700);
    font-size: 33.34px;
    font-weight: 700;
    line-height: 40.01px;
    text-align: left;
    color: var(--color-black);
}
.c_m_link >p{
    font-family: var(--font-500);
    font-size: 23.34px;
    font-weight: 500;
    line-height: 40.01px;
    text-align: left;
    color: #AEAEAE;
}
.contact_view_btn_div{

}
.contact_view_btn{
    width: 177px;
    height: 59px;
    /* box-shadow: 16px 14px 20px #0000008c; */
    border-radius: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
    margin-top: 1rem;
}
.contact_view_btn::before {
    content: "";
    background-image: conic-gradient(#595CFC 20deg, transparent 120deg);
    width: 198%;
    height: 326%;
    position: absolute;
    animation: rotate 3s linear infinite;
}
.contact_view_btn::after {
    content: "View open position";
    width: 172px;
    height: 54px;
    background-color: var(--color-white);
    position: absolute;
    border-radius: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-black);
    font-size: 18px;
    font-family: var(--font-300);
}

@media screen and (max-width: 767px) {
    .contact_method_base_wrapp{
        width: 90%;
    }
    .contact_method_title_div >h1 {
        font-family: var(--font-700);
        font-size: 35px;
        line-height: 49px;
        text-align: left;
        color: var(--color-black);
    }
    .contact_method_detail {
        padding: 4rem 0 1rem 0;
        display: flex;
        gap: 2rem;
        align-items: baseline;
        justify-content: end;
        flex-direction: column;
    }
    .c_m_link >a {
        font-family: var(--font-700);
        font-size: 27.34px;
    }
    .c_m_link {
        gap: 0;
    }
    .c_m_link >p {
        font-size: 20.34px;
    }
}
@media (min-width: 768px) and (max-width:1024px){
    .contact_method_base_wrapp{
        width: 90%;
    }
    .contact_method_detail {
        gap: unset;
        justify-content: space-between;
    }
}