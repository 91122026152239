.service_sec3_main_wrapp{
    width: 100%;
    margin: auto;
    height: 80vh;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    /* padding-top: 92px; */
}
.service_sec3_base_wrapp{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: var(--max-width-md);
    margin: auto;
    text-align: center;
}
.service_sec3_base_wrapp > h2{
    font-size: 35.71px;
    font-family: var(--font-inter);
    font-weight: 500;
    color: #808080;
    line-height: 43.22px;
}

@media(max-width: 767px){
    .service_sec3_main_wrapp {
        height: 62vh;
    }
    .service_sec3_base_wrapp{
            width: 90%;
    }
    .service_sec3_base_wrapp > h2{
        font-size: 25px;
    }
}
@media (min-width: 768px) and (max-width:1024px){
    .service_sec3_main_wrapp {
        height: 460px;
    }
}