.social>p, 
.stayconnected2 {
    font-family: var(--font-400);
    font-size: 13px;
}

.footer_main {
    padding: 20px 0;
    background-color: #000;
    border-top: 1px solid #777;
    z-index: 100;
    position: relative;
    width: 100%;
    margin: auto;
    height: auto;
}

.footer_base {
    max-width: var(--max-width);
    margin: auto;
}

.footer_row1 {
    display: flex;
    width: 100%;
    margin: 35px auto auto;
}

.logo_sec {
    width: 50%;
}

.footer_logo {
    width: 122px;
    height: 46px;
}

.stayconnected {
    width: 50%;
    text-align: end;
    display: flex;
    justify-content: flex-end;
}

.social {
    width: 301px;
    height: 69px;
    border: .75px solid #262626;
    border-radius: 9px;
    display: flex;
}

.social>p {
    margin: auto;
}

.social>a {
    padding: 10px 10px 10px 0;
}

.social>a>img {
    width: 48px;
    height: 48px;
    margin: auto auto auto 0;
}

.footer_row2 {
    display: flex;
    margin: 55px auto 24px;
}

.logo_sec2 {
    width: 50%;
    display: flex;
    gap: 20px;
}

.stayconnected2 {
    width: 50%;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    color: #98989a;
}

.emial {
    display: flex;
    gap: 7px;
    border-bottom: .5px solid rgba(58, 58, 58, .778);
    padding-bottom: 5px;
    cursor: pointer;
}

.emial>img {
    width: 15px;
    height: 15px;
}

.emial>p {
    font-size: 13px;
    font-family: var(--font-300);
}

@media (max-width: 767px) {
    .footer_row1 {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 35px auto auto;
    }

    .logo_sec {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .stayconnected {
        width: 100%;
        margin-top: 17px;
        justify-content: center;
    }

    .footer_row2 {
        display: flex;
        flex-direction: column;
        margin-top: 33px;
        margin-bottom: 0;
    }

    .logo_sec2 {
        width: 100%;
        display: flex;
        gap: 11px;
        justify-content: center;
    }

    .emial {
        display: flex;
        gap: 7px;
        padding-bottom: 7px;
    }

    .emial>p {
        font-size: 10px;
        margin-top: 2px;
    }

    .stayconnected2 {
        width: 100%;
        margin-top: 35px;
        justify-content: center;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .footer_base {
        width: 90%;
    }    
}