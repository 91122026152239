.CWW_main_wrapp {
    width: 100%;
    margin: auto;
    background-color: #27272A;
    padding: 6rem 0;
}

.CWW_base_wrapp {
    max-width: var(--max-width);
    margin: auto;
}

.CWW_base_wrapp>h1 {
    font-family: var(--font-500);
    font-size: 48px;
    font-weight: 500;
    line-height: 56.39px;
    text-align: left;

}

.CWW_content_div {
    display: flex;
    gap: 4rem;
    padding-top: 3rem;
}

.CWW_content_div_left {
    width: 50%;
    font-size: 19px;
    line-height: 22.79px;
    text-align: justify;
}

.CWW_content_div_right {
    width: 50%;
    font-size: 19px;
    line-height: 22.79px;
    text-align: justify;
}

.CWW_content_div_left>p {
    font-family: var(--font-300);
    font-weight: 400;
}

.CWW_content_div_right>p {
    font-family: var(--font-300);
    font-weight: 400;
}

@media(max-width: 767px) {
    .CWW_base_wrapp {
        width: 90%;
    }

    .CWW_content_div {
        flex-direction: column;
        gap: 2rem;
        padding-top: 1rem;
    }

    .CWW_content_div_left,
    .CWW_content_div_right {
        width: 100%;
        text-align: justify;
    }


    .CWW_base_wrapp>h1 {
        font-size: 35px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
.CWW_base_wrapp {
    width: 90%;
}
}