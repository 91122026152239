.cs_appmap_main_wrapp {
    width: 100%;
    margin: auto;
    background: var(--color-white);
    padding: 5rem 0;

}

.cs_appmap_base_wrapp {
    max-width: var(--max-width);
    margin: auto;
}

.cs_appmap_base_wrapp>h1 {
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
    color: var(--color-black);
    margin-left: 4%;
}

.cs_appmap_base_wrapp>h1>span {
    color: var(--color-black);
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;
    text-align: center;
}

.cs_appmap_img_div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}

.cs_appmap_circle {
    position: relative;
    width: 559px;
    height: 559px;
    border-radius: 50%;
    border: 8px solid #4BC9F8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cs_appmap_circle2 {
    position: relative;
    width: 559px;
    height: 559px;
    border-radius: 50%;
    /* border: 8px solid #4BC9F8; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.cs_appmap_circle>img {
    position: absolute;
    height: 555px;
    bottom: 73px;
}

.cs_appmap_circle2>img {
    position: absolute;
    height: 555px;
    bottom: 73px;
}

@media(max-width: 768px) {
    .cs_appmap_base_wrapp {
        width: 90%;
    }

    .cs_appmap_base_wrapp>h1 {
        margin-left: 0;
        font-size: 35.66px;

    }

    .cs_appmap_circle {
        width: 380px;
        height: 380px;
        border-radius: 50%;
        border: 8px solid #4BC9F8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cs_appmap_circle2 {
        width: 380px;
        height: 380px;
        border-radius: 50%;
        /* border: 8px solid #4BC9F8; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cs_appmap_circle>img {
        height: 352px;
        bottom: 56px;
    }
    .cs_appmap_circle2>img {
        height: 352px;
        bottom: 32px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .cs_appmap_circle {
        position: relative;
        width: 518px;
        height: 518px;

    }

 

    .cs_appmap_circle>img {
        position: absolute;
        height: 475px;
        bottom: 63px;
    }
    .cs_appmap_circle2>img {
        position: absolute;
        height: 475px;
        bottom: 63px;
    }

}