.service_branding_main_wrapp{
    width: 100%;
    /* margin:4rem auto; */
    height: 760px;
    position: relative;
    z-index: 3;
    background-color: black;
}
.sb_hero_img_div {
    position: relative;
    display: inline-block; 
    width: 100%;
}

.sb_hero_img_div img {
    display: block;
    width: 100%;
    height: 560px;
    object-fit: cover;
}

.sb_hero_img_div .sb_hero_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(2.73deg, #000000 3.19%, rgba(0, 0, 0, 0.44) 70.03%);

}
.sb_contact_div{
    max-width: var(--max-width);
    transform: translateY(-17rem);
    margin: auto;
}
.sb_contact_card{
    background: linear-gradient(109.56deg, rgba(41, 34, 54, 0.5) 0%, rgba(0, 0, 0, 0.5) 126.32%), linear-gradient(0deg, #1d182487, #1d182447);
    border: 1px solid;

    border-image-source: linear-gradient(119.94deg, #707070 1.68%, rgba(112, 112, 112, 0.33) 46.68%);
    max-width: 594px;
    min-height: 349px;
    border-radius: 16px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sb_contact_card>h1 {
    font-family: var(--font-poppins);
    font-size: 28px;
    font-weight: 700;
    line-height: 37.4px;
    text-align: left;
    width: 86%;
}
.sb_contact_card > p{
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;

}
/* .sb_contact_card_button{
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 51px;
    max-width: 137px;
} */
.sb_contact_card_button {
    width: 177px;
    height: 59px;
    /* box-shadow: 16px 14px 20px #0000008c; */
    border-radius: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
  }
  .sb_contact_card_button::before{
  content: "";
  background-image: conic-gradient( #595CFC 20deg, transparent 120deg );
  width: 198%;
  height: 326%;
  position: absolute;
  animation: rotate 3s linear infinite 
  }
  .sb_contact_card_button::after{
  content: "Contact Us";
  width: 172px;
  height: 54px;
  background-color: #12111a;
  position: absolute;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-size: 18px;
  font-family: var(--font-300);
  }
  .sb_contact_card_button:hover {
  background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
  transition: 0.5s all;
  }
  .sb_contact_card_button:hover::after {
  background-color: transparent;
  color: var(--color-white);
  /* transition: 0.5s all; */
  }
  .sb_contact_card_button:hover::before {
  background-image: none;
  /* transition: 0.5s all; */
  }
  .sb_contact_card >a{
    width: max-content;
  }

@media(max-width: 767px){
    .service_branding_main_wrapp {
        width: 100%;
        margin: 0rem auto;
        height: auto;
    }
.sb_contact_div {
    transform: translateY(0);
    margin: auto;
    width: 90%;
}
.sb_contact_card>h1 {
    font-size: 28px;
    line-height: 37.4px;
    text-align: left;
    width: 86%;
}
.sb_contact_card > p {
    font-size: 16px;
    line-height: 22.8px;
}
.sb_hero_img_div img {
    height: auto;
    object-fit: unset;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
    .sb_contact_div {
        width: 90%;
    }
}   