.Service4_banner_main {
    display: flex;
    margin-top: 95px;
    position: fixed;
    /* Fix the banner in place */
    z-index: 1;
    /* Ensure it is below the main content */
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden; /* Prevent overflow issues */
    height: 460px;
}

.Service4_banner_main22 {
    position: fixed;
    /* Fix the banner in place */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    /* Ensure it is below the main content */
}

.s4_left {
    width: 50%;
    font-size: 48px;
    font-family: var(--font-aeoniktrial)!important;
    margin: auto;
    /* text-align: center; */
    padding-left: 13%;
}

.s4_right {
    width: 50%;

}

.s4_right>img {
    width: 100%;
    height: 100%;
}

.s4_left>h2 {
    font-size: 28px;
}



/*  */
@media (max-width: 768px) {
    .Service4_banner_main {
        display: flex;
        flex-direction: column;
        margin-top: 96px;
        gap: 54px;
        justify-content: center;
        align-items: center;

    }

    .s4_left {
        position: absolute;
        z-index: 1;
        padding-left: 0;
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .s4_right {
        width: 100%;
        position: relative;
    }

    .s4_right>img {
        height: 420px;
    }

}