.home_boder_main_wrapp{
    max-width: var(--max-width);
    margin: auto;
    width: 85%;
}
.borderr {
    border: 2px solid grey;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    text-align: center;
    margin: auto;
    padding: 45px 20%;
    padding-bottom: 65px;
    margin-bottom: 5rem;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
}
.borderr> h1 {
    font-size: 59px;
    /* font-family: var( --font-aeoniktrial)!important; */
    line-height: 73px;
    max-width: 528px;

}
.borderr>p {
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    font-family: var(--font-300);
}
@media(max-width: 786px){
    .home_boder_main_wrapp {
        width: 90%;
    }
    .borderr {
        gap: 16px;
   
        padding: 34px 2%;
}
.borderr>button {
    width: 176px;
    height: 61px;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 8px;
}
.borderr>button::after{
    width: 170px;
    height: 55px;
    
}
.borderr> h1 {
    font-size: 35px;
    line-height: 42px;
}
.borderr>p {
    font-size: 16px;
    line-height: 24px;
}
}

@media (min-width: 768px) and (max-width:1024px){

.borderr {
 
  padding: 45px 2rem;
  
}
}

/* lets talk button */
.letstalk-button {
    width: 177px;
    height: 59px;
    /* box-shadow: 16px 14px 20px #0000008c; */
    border-radius: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
    margin-top: 1rem;
}
.letstalk-button::before{
  content: "";
  background-image: conic-gradient( #595CFC 20deg, transparent 120deg );
  width: 198%;
  height: 326%;
  position: absolute;
  animation: rotate 3s linear infinite 
}
.letstalk-button::after{
  content: "Lets Talk";
  width: 172px;
  height: 54px;
  background-color: var(--color-black);
  position: absolute;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-size: 18px;
  font-family: var(--font-300);
}
.letstalk-button:hover {
  background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
  transition: 0.5s all;
}
.letstalk-button:hover::after {
  background-color: transparent;
  color: var(--color-white);
  /* transition: 0.5s all; */
}
.letstalk-button:hover::before {
  background-image: none;
  /* transition: 0.5s all; */
}


@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-360deg);
    }
}
/* contact us today button */
.contactustoday-button {
    width: 177px;
    height: 59px;
    /* box-shadow: 16px 14px 20px #0000008c; */
    border-radius: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
    margin-top: 1rem;
}
.contactustoday-button::before{
  content: "";
  background-image: conic-gradient( #595CFC 20deg, transparent 120deg );
  width: 198%;
  height: 326%;
  position: absolute;
  animation: rotate 3s linear infinite 
}
.contactustoday-button::after{
  content: "Contact us today";
  width: 172px;
  height: 54px;
  background-color: var(--color-black);
  position: absolute;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-size: 18px;
  font-family: var(--font-300);
}
.contactustoday-button:hover {
  background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
  transition: 0.5s all;
}
.contactustoday-button:hover::after {
  background-color: transparent;
  color: var(--color-white);
  /* transition: 0.5s all; */
}
.contactustoday-button:hover::before {
  background-image: none;
  /* transition: 0.5s all; */
}
.width-100{
    width: 100%;
    max-width: 100% !important;
}


@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-360deg);
    }
}


