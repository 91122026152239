.jb_main_wrapp {
    width: 100%;
    margin: auto;
    height: 70vh;
    margin-top: 92px;
    position: relative;
}
.jb_main_wrapp > img {
    width: 100%;
    height: 79%;
    position: absolute;
    top: 21%;
}
.jb_content_div {
    position: relative;
    transform: translate(0%, 50%);
    max-width: 1280px;
    margin: auto;
    z-index: 1;
    text-align: center;
}
.jb_overlay {
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    width: 100%;
    top: 0;
    position: absolute;
}
.jb_content_div >h1 {
    font-size: 54.61px;
    font-weight: 500;
    line-height: 65.53px;
    letter-spacing: -0.11376778036355972px;
    text-align: center;    
    font-family: var(--font-aeoniktrial)!important;
}
.jb_content_div > h2{
    font-size: 17.07px;
    font-weight: 400;
    line-height: 27.3px;
    letter-spacing: -0.11376778036355972px;
    text-align: center;
}
.jb_content_div > h3{
    font-size: 17.07px;
    font-weight: 400;
    line-height: 27.3px;
    letter-spacing: -0.11376778036355972px;
    text-align: center;
    /* padding-bottom: 2rem; */
}
.jb_content_div >a>button {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: center;
    border: 1px solid white;
    height: 59px;
    border-radius: 120px;
    padding: 1rem 2rem;
    margin-top: 2rem;
    position: relative;
    overflow: hidden;
}

.jb_btn_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}
.jb_btn_container > a{
    cursor: pointer;
}
.jb_link2{
    border: 1px solid;
    height: 38.6px;
    width: 38.6px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jb_link3 > img{    
    height: 38.6px;
    width: 38.6px;
}


@media (max-width: 767px) {
    .jb_content_div {
        width: 90%;
        transform: translate(0%, 32%);
    }
    .jb_content_div >h1{
    font-size: 35px;
    line-height: 45px;
    padding-bottom: 2rem;
    /* width: 343px; */
    }
    .jb_main_wrapp > img {
        height: 70%;
        top: 30%;
    }
}