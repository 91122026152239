.about_coreval_main_wrapp{
  width: 100%;
  margin: auto;
}
.about_coreval_base_wrapp{
  max-width: var(--max-width);
  margin: auto;
}
.about_core_title_div{
  max-width: var(--max-width);
  /* width: 85%; */
  margin: auto;
  display: flex;
  gap: 2rem;
  align-items: center;
}
.about_core_title_div > h1{
  font-size: 48px;
  font-family: var(--font-500);
  line-height: 78px;
}
.about_core_title_div >hr{
  border: 2px solid;
  border-image-source: linear-gradient(90deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%);
  border-image-slice: 1;
  width: 50%;
}
.about_core_card_div {
  display: flex;
  flex-wrap: nowrap; 
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  height: auto;
}

.about_core_card1 {
  flex: 1;
  border-right: 0.74px solid #979797; 
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* cursor: pointer; */
  transition: background 0.3s, color 0.3s; /* Added transition property */
}
.about_core_card1_last {
  flex: 1;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* cursor: pointer; */
}
.about_core_card1:hover {
  color: var(--color-black);
}
.about_core_card1:hover{
  background: var(--color-white);

}
.about_core_card1:hover h1{
  color: var(--color-black);
}

.about_core_card1:last-child {
  border-right: none; /* Remove left border from last card */
}

.about_core_card1 h1 {
  margin: 0; 
  font-size: 34.12px;
  font-weight: 400;
  font-family: var(--font-inter);
  /* width: 75%; */
}

.about_core_card1_last img {
  width: 140px;
  height: 140px;
  animation: rotate360 10s linear infinite; 
}
.about_core_card_wrapp{
  width: 100%;
  /* margin-left: auto; */
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media(max-width: 768px){
  .about_core_title_div {
      width: 90%;
      flex-direction: column;
      gap:1rem
  }
  .about_core_title_div >hr {
      width: 99%;
  }
  .about_core_title_div > h1 {
      font-size: 35px;
      line-height: 66px;
  }
  .about_core_card1 {
      flex: none;
      box-sizing: border-box;
      width: 325px;
      height: 376px;
      border-left: 0.74px solid #979797;
  }
  .about_core_card1_last {
      flex: none;
      box-sizing: border-box;
      width: 325px;
      height: 376px;
      border-left: 0.74px solid #979797;
      border-right: 0.74px solid #979797;
  }
    .about_core_card_div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  }
  .about_core_card1:last-child {
      border-right:0.74px solid #979797;
  }
  .about_core_card_wrapp {
      width: 90%;
      margin: auto;
  }
  .about_core_card1 h1 {
    font-size: 27.12px;
}
}

@media (min-width: 768px) and (max-width:1024px){
  .about_core_card1 h1 {
    font-size: 23.12px;
    width: 100%;
}
.about_core_title_div {
  width: 90%;
}
.about_core_card_wrapp {
  width: 100%;

  margin: auto;
}
}
