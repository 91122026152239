/* Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 20px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.navbar.scrolled {
  background-color: var(--color-black);
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  margin: auto;
  /* padding: 1rem; */
}

.nav_logo {
  width: 100px;
  height: 60px;
  object-fit: contain;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: var(--color-white);
  font-family: var(--font-300);
  font-size: var(--font-18);
  font-weight: 500;
}
.nav_sidebar_wrapp {
  background: rgb(255, 255, 255);
  flex-direction: column;
  position: fixed;
  right: 0px;
  top: 84px;
  display: none;
  /* backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}
.navbar_inner_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding:0 1rem 1rem 1rem;
}

.navbar_inner_wrapp > a {
  font-family: var(--font-300);
  font-size: var(--font-18);
  font-weight: 600;
  color: black;
  border-bottom: 1px solid #ffffff55;;
  padding: 2rem 0 2rem 0;
  width: 100%;
}

.navbar_inner_wrapp_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  gap: 1rem;
  flex-wrap: wrap;
}
/* sidebar icon start */

#checkbox {
  display: none;
}

.toggle {
  display: none;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: var(--color-white);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1 {
  width: 50%;
}

#bar2 {
  width: 75%;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.1s;
}

#checkbox:checked + .toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}
.nav_single_link:hover {
  border-top: 4px solid;
  border-image: linear-gradient(90deg, #5691E9 0%, #00A0A7 100%);
    border-image-slice: 1;
    padding-top: 17px;
}

.nav_dropdown_content_side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}
.nav_dropdown_content_side > a {
  display: block;
  padding-bottom: 7px;
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  font-family: var(--font-300);
  font-size: 17px;
  font-weight: 600;
  opacity: 70%;
}
.nav_dropdown_content_inner_side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-left: 3rem;
}
.nav_dropdown_content_inner_side > a {
  display: block;
  padding-bottom: 10px;
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  font-family: var(--font-300);
  font-size: 17px;
  font-weight: 600;
  opacity: 70%;
  line-height: 15px;
}

/* sidebar icon end */

/* solution menu */
.menu_img_div{
  background: #1B1B1D;
  border-radius: 15px;
  width: 12rem;
  display: flex;
    justify-content: center;
    align-items: center;
    
}
.menu_img_div >img{
  width: 90px;
  height: 90px;
  
}
.menu_border_white{
  border: 1px solid #343437;
}
/* .menu_list_div{
  width: 100%;
} */
.menu_list_div >h4{
  font-family: var(--font-700);
font-size: 19.34px;
font-weight: 700;

}
.nav_pro_item_link {
  font-family: var(--font-400);
  font-size: 13px!important;
  font-weight: 500!important;
  opacity: 0.7; /* Initial opacity */
  transition: opacity 0.3s ease; /* Define the transition effect */
}

.nav_pro_item_link:hover {
  opacity: 1; /* Lower opacity on hover */
}

.absolute-menu-item{
  transform: translate(-33%);
}


.solution_drop{
  display: flex;
    justify-content: space-between;
    font-family: var(--font-300);
    font-size:20px;
    font-weight: 600;
    color: white;
    border-bottom: 1px solid #ffffff55;
    padding: 2rem 0 2rem 0;
    width: 100%;
}
.overview_nav_btn{
  font-size: 18px !important;
  font-weight: 300 !important;
}
/* .pronav-solu :hover{
  border-top: 4px solid;
  border-image: linear-gradient(90deg, #5691E9 0%, #00A0A7 100%);
  border-image-slice: 1;
  padding-top: 17px;
} */
@media (max-width: 768px) {
.Nav_btn_toggle_wrap{
  display: flex;
  gap: 2rem;
}
  
  .nav-links {
    flex-direction: column;
    display: none;
  }
  .navbar-content {
    /* height: 84px; */
}
  

.btn-div-side{
  width: 100%;
  padding: 2rem 0;
}

  .toggle {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 7px;
    transition-duration: 0.5s;
    margin-top: 4px;
  }
  .sidebar_logo {
    display: block;
  }

  .nav_links,
  .nav_links_apply_wrapp {
    display: none;
  }

  .nav_sidebar_wrapp {
    background: var(--color-black);
    flex-direction: column;
    position: fixed;
    right: 0px;
    top: 91px;
    display: block;
    /* backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }

  .nav_sidebar_wrapp > a {
    padding: 1rem;
    border-bottom: 1px solid #c1c1c1;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    text-align: right;
    color: var(--color-white);
    cursor: pointer;
    /* background: var(--color-orange); */
  }
  .navbar_inner_wrapp > a {
    font-size: 20px;
    color: var(--color-white);

  }

  .nav_sidebar_wrapp > a:hover {
    font-weight: bold;
   
  }
  
}

/* lets talk button */
.nav-getintouch-btn {
  width: 128px;
  height: 40px;
  /* box-shadow: 16px 14px 20px #0000008c; */
  border-radius: 120px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e4e4e4a8
}
.nav-getintouch-btn::before{
content: "";
background-image: conic-gradient( #595CFC 20deg, transparent 120deg );
width: 102%;
height: 457%;
position: absolute;
animation: rotate 3s linear infinite 
}
.nav-getintouch-btn::after{
content: "Get in Touch";
width: 124px;
height: 36px;
background-color: var(--color-black);
position: absolute;
border-radius: 120px;
display: flex;
justify-content: center;
align-items: center;
color: var(--color-white);
font-size: 17px;
font-family: var(--font-300);
}
.nav-getintouch-btn:hover {
background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
transition: 0.5s all;
}
.nav-getintouch-btn:hover::after {
background-color: transparent;
color: var(--color-white);
/* transition: 0.5s all; */
}
.nav-getintouch-btn:hover::before {
background-image: none;
/* transition: 0.5s all; */
}


@keyframes rotate {
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(-360deg);
  }
}

