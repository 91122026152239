.cs_userpersonal_main_wrapp{
    width: 100%;
    margin: auto;
    background-color: var(--color-white);
    /* padding-bottom: 5rem; */
    padding: 5rem 0;

}
.cs_userpersonal_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.cs_up_cards_div{
    display: flex;
    gap: 2rem;
    /* height: 680px; */
    height: 735px;
}
.cs_up_card_left{
width: 40%;
background: var(--color-black);
border-radius: 40px;
display: flex;
justify-content: space-between;
flex-direction: column;
padding: 2rem 2rem;

}
.cs_up_card_left > h2{
font-size: 49.66px;
font-weight: 700;
line-height: 60.09px;
color: var(--color-white);
}
.cs_up_card_left >h2 >span{
font-style: italic;
font-weight: 400;
line-height: 60.09px;
text-align: center;
color: var(--color-white);
}
.cs_up_black_div{
    background: #1A1A1B;
    /* min-height: 218px; */
    border-radius: 25px;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
}
.cs_up_cards_right{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}
.cs_up_cards_right >img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
}
.cs_up_left{

}
.cs_up_left_item{

}
.up_age {
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px 13px;
    height: 35px;
    border-radius: 10px;
    border: 2px solid #4ABFF0;
    width: max-content;
    /* margin: auto; */
    /* margin-bottom: 9px; */
    transform: rotate(11deg);
    position: relative;
    left: 112px;
    bottom: 86px;
}
.up_name{
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px 13px;
    height: 35px;
    border-radius: 10px;
    border: 2px solid #4ABFF0;
    width: max-content;
    margin: auto;
    /* margin-bottom: 9px; */
    transform: rotate(-12deg);
    position: relative;
    left: -45px;
    bottom: 79px;

}
.up_occupation {
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px 59px;
    min-height: 35px;
    border-radius: 10px;
    border: 2px solid #4ABFF0;
    max-width: max-content;
    /* margin: auto; */
    /* margin-bottom: 11px; */
    transform: rotate(13deg);
    position: relative;
    right: -12px;
    bottom: 46px;
}
.cs_up_black_div >div{
    display: flex;
    flex-direction: column;
    gap: 7px;

}
.cs_up_black_div >div>h1{
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
}
.cs_up_black_div > div >p{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.04px;
}
.cs_up_black_div > div >ul{
    list-style-type: disc;
    padding-left: 14px;
}
.cs_up_black_div > div >ul>li{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.04px;
}
@media (max-width: 767px) {
    .cs_userpersonal_base_wrapp{
        width: 90%;
    }
    .cs_up_cards_div {
        height: auto;
        flex-direction: column;
    }
    .cs_up_card_left {
        width: 100%;
        /* height: 650px; */
    }
    .cs_up_cards_right {
        width: 100%;
        height: 400px;
    }
    .cs_up_left_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 247px;
    }
    .cs_up_black_div {
        min-height: max-content;
        gap: 1.5rem;
    }
    .up_age {
        left: -51px;
        bottom: -24px;
        font-size: 13px;
    }
    .up_occupation {
        height: auto;
        width: auto;
        min-height: auto;
        max-width:auto;
        font-size: 13px;
        padding: 7px 35px;
        bottom: 63px;
    }
    .up_name {
        left: -45px;
        bottom: 34px;
        font-size: 13px;
    }
    .cs_up_card_left > h2 {
        font-size: 35.66px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .cs_userpersonal_base_wrapp{
        width: 90%;
    }
    .cs_up_cards_div {
        height: auto;
        flex-direction: column;
    }
    .cs_up_card_left {
        max-width: 487px;
        height: 650px;
        margin: auto;
        width: auto;
    }
    .cs_up_cards_right {
        max-width: 487px;
        height: 650px;
        margin: auto;
        width: auto;
    }
    .cs_up_left_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cs_up_black_div {
        min-height: max-content;
        gap: 1.5rem;
    }
    .up_age {
        left: -51px;
        bottom: 103px;
        font-size: 13px;
    }
    .up_occupation {
        height: auto;
        width: auto;
        min-height: auto;
        max-width:auto;
        font-size: 13px;
        padding: 7px 39px;
    }
    .up_name {
        left: -45px;
        bottom: 88px;
        font-size: 13px;
    }
}