.paras4{
    background-color: white;
    padding: 95px 18%;
    position: relative;
  z-index: 2; /* Ensure it is above the banner */
  margin-top: 555px; 
}
.paras4 > h1 {
    font-size: 35px;
    font-family: var(--font-600);
    color: #808080;
    text-align: center;
}
@media (max-width: 768px){
  .paras4 {
      margin-top: 60vh;
      padding: 78px 6%;
  }
  .paras4 > h1 {
    font-size: 25px;
    line-height: 40px;
  }
  .ser_main > h1 {
    font-size: 25px;
  }
}