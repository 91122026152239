.section1_mainn{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    max-width: var(--max-width);

}
.section1_title{
    font-size: 70px;
    font-family: var(--font-aeoniktrial)!important;
    letter-spacing: -1.82px;
    color: var(--color-white);
    line-height: 78px;
    font-weight: 400;
}
.line1{
    width: 37%;
    height: 2px;
    background: #FFFFFF;
    /* padding: 9px -2px; */
    margin: 0px;
    margin-top: 136px;

}
.section1_title_2{
    font-size: 70px;
    font-family: var(--font-aeoniktrial)!important;
    letter-spacing: -1.82px;
    color: var(--color-white);
    margin-top: 85px;
    text-align: end;
    line-height: 78px;
    font-weight: 400;
}
@media(max-width: 768px){
    .section1_mainn{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
    }
    .section1_title{
        font-size: 42px;
        line-height: 55px;
        margin: auto;
    
    }
    .line1{
        width: 50%;
        height: 2px;
        background: #FFFFFF;
        margin: auto;
        margin-top: 38px;

    
    }
    .section1_title_2{
        font-size: 42px;
        line-height: 55px;
        margin: auto;
        margin-top: 43px;
        text-align: center;

    }

}
@media (min-width: 769px) and (max-width:1024px){
    .section1_mainn{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
    }
    .section1_title{
        font-size: 65px;
        margin: auto;
    
    }
    .line1{
        width: 50%;
        height: 2px;
        background: #FFFFFF;
        margin: auto;
        margin-top: 38px;

    
    }
    .section1_title_2{
        font-size: 65px;
        margin: auto;
        margin-top: 43px;
    
    }
}