.about_discover_main_wrapp{
    width: 100%;
    margin: auto;
}
.about_discover_img{
    width: 100%;
}
.about_discover_img_container {
    position: relative;
  }
  
  .about_discover_img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .about_discover_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 145.68%);
  }
  .about_discover_base_wrapp {
    position: relative;
    text-align: center; /* Center text horizontally */
  }
  .about_discover_title_div{
    max-width: var(--max-width);
    margin: auto;
  }
  .about_discover_title{
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 80%;
    display: flex;
    gap: 3rem;
    max-width: var(--max-width);
  }
  .ppppppp {
     /* Center text vertically and horizontally */
    color: #fff; /* Set text color */
    /* Ensure text appears above overlay */
    font-size: 48px;
    font-family: var(--font-500);
    line-height: 70px;
    text-align: left;
    max-width: 922px;
  }
  
  .headerr{
    width: 0.5rem;
    color: #fff; /* Set text color */
    height: auto;
    background: linear-gradient(180deg, #5EF1E3 65.83%, #595AFD 100%);
  }
  .about_discover_button{
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 1rem;
    /* padding-top: 5rem;ss */
    max-width: 1280px;
    width: 80%;
  }
  .about_discover_button > p{
    font-size: 20px;
    font-family: var(--font-400);
  }
  .about_discover_cards_div {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -21px;
    width: 80%;
    gap: 4rem;
    align-items: center;
    max-width: 80%;
    margin-left: 13%;
  }
  
  .about_discover_card {
    flex: 0 0 calc(23% - 10px); 
    margin-bottom: 20px; 
    background-color: var(--color-white); 
    text-align: center; 
    height: 126px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about_discover_card h2 {
    font-size: 33px;
    font-family: var(--font-500);
    color: var(--color-black);
  }
  
  .a_d_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    .ppppppp {
      font-size: 35px;
      line-height: 43.78px;
      width: 327px;
    }
    .about_discover_title{
        gap: 2rem;
        top: 42%;
        width: 90%;
    }
    .about_discover_cards_div {
        position: unset;
        width: 90%;
        gap: 4rem;
        align-items: center;
        max-width: 100%;
        flex-direction: column;
        margin-left: 0;
        margin: auto;
        padding: 4rem 0;
    }
    .about_discover_img {
        display: block;
        width: auto;
        height: 400px;
        object-fit: cover;
    }
    .about_discover_button {
      top: 62%;
      left: 10%;
        transform: translate(0);
        padding-top: 2rem;
    }
    .about_discover_card {
        width: calc(80% - 10px);
        flex-basis: unset;
        height: 126px;
    }
  }
  @media (min-width: 768px) and (max-width:1024px){

    .about_discover_img {
        height: 684px;
        object-fit: cover;
    }
    .about_discover_button {
        left: 10%;
        transform: translate(0);
        padding-top: 4rem;
    }
    .about_discover_cards_div {
        width: 100%;
        gap: 1rem;
        align-items: center;
        max-width: 100%;
        margin-left: 0;
    }
    .ppppppp {
        font-size: 50px;
        line-height: 63.78px;
    }
}
@media (min-width: 1024px) and (max-width:1210px){
  .ppppppp {
    font-size: 48px;
}
.about_discover_button {
  position: absolute;
  top: 74%;
  left: 21%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 0rem;
}
}