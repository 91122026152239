.ourserice_4{
    padding-bottom: 50px;
    position: relative;
    z-index: 3;
    background: black;
   
}
.wid{
     max-width: var(--max-width);
    margin: auto;
    width: 100%;
}
.ourserice_44{
    display: flex;
    padding-bottom: 50px;
   
}
.ser_main{
    width: 93%;
    text-align: center;
    margin: auto;
    padding-top: 78px;
}
.ser_main > h2{

    font-size: 36px;
    font-family: var(--font-400);
}
.ser_main > h1{
    font-size: 32px;
    font-family: var(--font-400);
    margin-top: 10px;
    margin-bottom: 96px;
    margin-bottom: 58px;
}
.ourserv_4_left{
    width: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ourserv_4_left >h3 {
    font-size: 32px;
    font-family: var(--font-300);
    padding-left: 21%;
    margin: 47px 0px;
    /* margin-bottom: 31px; */
    display: flex;
    gap: 10px;
    

}
.hashtag1{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 57px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    /* margin: auto; */
    /* margin-bottom: 9px; */
    transform: rotate(-11deg);
    position: relative;
    /* left: 59px; */
    top: 7px;

}
.hashtag2{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 57px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    margin: auto;
    /* margin-bottom: 9px; */
    transform: rotate(7deg);
    position: relative;
    left: 84px;
    top: 7px;
}
.hashtag3{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 59px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    /* margin: auto; */
    /* margin-bottom: 11px; */
    transform: rotate(-3deg);
    position: relative;
    right: 52px;
    bottom: 3px;

}
.hashtag4{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 57px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    /* margin: auto; */
    /* margin-bottom: 9px; */
    transform: rotate(-15deg);
    position: relative;
    left: 59px;
    bottom: 14px;
}
.hashtag5{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 107px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    margin: auto;
    /* margin-bottom: 9px; */
    transform: rotate(7deg);
    position: relative;
    left: -27px;
    bottom: -9px;

}
.hashtag6{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 57px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    margin: auto;
    /* margin-bottom: 9px; */
    transform: rotate(-8deg);
    position: relative;
    left: -116px;
    top: 25px;
}
.hashtag7{
    font-family: 'Font600';
    font-size: 22px;
    color: #6969ed;
    background: #57575d29;
    padding: 12px 57px;
    border-radius: 5px;
    border: 1px dashed #5864F9;
    width: max-content;
    margin: auto;
    /* margin-bottom: 9px; */
    transform: rotate(-2deg);
    position: relative;
    left: 59px;
    bottom: -12px;
}
.ourserv_4_right{
    width: 50%;
    margin: auto;

}
.ser_acco{
    /* display: flex; */
    font-family: var(--font-400);
    gap: 20px;
    align-items: center;
    padding: 20px 0px;
    border-bottom: 1px dashed #5864F9;
    /* width: 86%; */
    width: 100%;
    position: relative;
    cursor: pointer;
}
.ser_acco>p{
    padding-top: 16px;
    font-size: 16px;
}
.ser_acco_h{
    display: flex;

}
.ser_acco_h>h5{
    font-size: 20px;
    color: grey;
    margin-right: 151px;
    font-family: var(--font-600);

}


.ser_acco_h>h3{
    font-size: 40px;
    color: #3b3bd3;
    position: absolute;
    right: 0;
    top: 5px;

}
.ser_acco:hover .ser_acco_h >h5{
    color: #5864F9;
    margin-right: 151px;
}
.content{
    border-bottom: 1px dashed #5864F9;

}
.left_item{
    transform: translateX(-80px);
}
@media (max-width: 767px){
    .wid{
       width: 90%;
   }
    .ourserv_4_right {
        width: 100%;
    }
    .ourserv_4_left {
        width: 100%;
        padding: 26px;
        padding-left: 2px;
        padding-top: 6px;
    }
    .ser_acco {
      
        width: 100%;
    }
    .ourserice_44 {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }
    .left_item {
        transform: translateX(0px);
    }
    .hashtag1,
    .hashtag2,
    .hashtag3 ,
    .hashtag4,
    .hashtag5,
    .hashtag6,
    .hashtag7{
        padding: 7px 45px;
    }
    .hashtag1{
        margin-bottom: 9px;
    }
    .hashtag2{
        margin-bottom: 9px;
        left: 84px;
    
    }
    .hashtag3{
        margin-bottom: 9px;
        right: 46px;
    
    }
    .hashtag4{
        margin-bottom: 9px;
        left: 35px;
        bottom: 17px;
    
    }
    .hashtag5{
        margin-bottom: 9px;
        left: 7px;
        bottom: 10px;
    
    }
    .hashtag6{
        margin-bottom: 9px;
        left: -65px;
        top: 3px;
    
    }
    .hashtag7{
        margin-bottom: 9px;
        left: 59px;
        bottom: 14px;
    }
    .ser_main > h2 {
        font-size: 32px;
    }
}

/* @media (min-width: 769px) and (max-width: 1024px) {
    .wid{
       width: 90%;
   }
    .ourserv_4_right {
        width: 100%;
    }
    .ourserv_4_left {
        width: 100%;
        padding: 26px;
        padding-top: 6px;
    }
    .ser_acco {
      
        width: 100%;
    }
    .ourserice_44 {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }
} */


@media (min-width: 769px) and (max-width: 1024px) {
    .ourserice_44 {
        width: 90%;
        margin: auto;
    }
    
    .left_item {
        transform: translateX(-26px);
    }
    .hashtag7 {
        padding: 12px 46px;
    }
    .hashtag2 {
        padding: 12px 33px;
    }
}