.homeinsight_main_wrapp{
    width: 100%;
    background: var(--color-white);
    margin: auto;
    padding: 2rem 0;
    height: auto;
}
.homeinsight_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
    width: 85%;
}
.homeinsight_base_wrapp>h1{
    font-size: 48px;
    font-family: var(--font-500);
    color: var(--color-black);
    line-height: 70px;
}
.homeinsight_base_wrapp > p{
    font-size: 24px;
    font-family: var(--font-400);
    color: var(--color-black);
    line-height: 28.79px;
}
.homeinsight_card_div{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* margin: 2.5rem 0 2rem 0; */
    margin: 2rem 0 2rem 0;
    gap: 1.5rem;
    height: 550px;
}
.homeinsight_card_l1{
    width: 67%;
    display: flex;
    flex-direction: row;
    border-bottom: 0.97px solid #9C9C9C;
    border-top: 0.97px solid #9C9C9C;
    border-right: 0.97px solid #9C9C9C;
    border-radius: 13.52px;
    cursor: pointer;
}
.homeinsight_card_r1{
    width: 31%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.HI_l_img_div{
    width: 60%;
    position: relative;
}
.HI_l_del_div{
    padding: 3rem 3rem 15px 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
}
.HI_l_img{
    width: 100%;
    height: 550px;
    position: absolute;
    top: -1px;
    border-radius: 13.51px;
    object-fit: cover;
}
.HI_title{
    font-size: 28.97px;
    font-family: var(--font-exo);
    color: var(--color-black);
    line-height: 30.58px;
    padding-bottom: 1rem;
    letter-spacing: 0.55px;
    padding-top: 4rem;
    text-align: left;
    font-weight: 600;
}
.HI_des{
    font-size: 12.56px;
    font-family: var(--font-exo);
    color: var(--color-black);
    line-height: 17px;
    letter-spacing: 0.55px;
    font-weight: 400;
}
.HI_date{
    font-size: 12.56px;
    font-family: var(--font-exo);
    color: var(--color-black);
    line-height: 28.79px;
}
.HI_r_img{
    width: 100%;
    height: 258px;
    border-radius: 14px;
    object-fit: cover;
}
.HI_r_card_div{
    position: relative;
    cursor: pointer;
    border-radius: 13.52px;
}
.HI_r_card_div::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;
    height: 98%;
    border-radius: 13px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 39.74%, rgba(0, 0, 0, 0.90) 100%);
}
.text-overlay {
    position: absolute;
    bottom: 9%;
    left: 2%;
    color: white;
    padding: 10px;
    z-index: 1;
    font-size: 23.18px;
    font-family: var(--font-exo);
    font-weight: 600;
    transition: transform 0.5s;
  }


  .homeinsight_card_l1 {
    position: relative;
    transition: transform 0.5s, box-shadow 0.5s; /* Add box-shadow transition */
  }
  
  .homeinsight_card_l1:hover {
    box-shadow: -1px 14px 34px 0px rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    border-top: 0;
    border-right: 0;
    }
    .homeinsight_card_l1:hover .HI_l_img {
        top: 0;
    }


  
  .HI_l_img_div,
  .HI_l_del_div {
    transition: transform 0.5s;
  }
  
  .HI_r_card_div:hover{
    box-shadow: -3px 17px 24px 0px rgba(0, 0, 0, 0.2);
  }
  .homeinsight_card_l1:hover .HI_l_del_div {
    transform: translate3d(10px, 0px, 0);

    }
    .HI_r_card_div:hover .text-overlay {
        transform: translate3d(10px, 0px, 0);
        transition: transform 0.5s;

    }
.black_shadow:hover {
        box-shadow: -1px 14px 34px 0px rgb(209 209 209 / 30%);
        
}
.black_shadow1:hover {
    box-shadow: -1px 5px 21px 0px rgb(215 212 212 / 30%);
}
  
  
  @media(max-width: 480px){
    .homeinsight_main_wrapp {
        padding: 3rem 0 4rem 0; 
    }
    
.homeinsight_card_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 50vh; */
    margin: 2rem 0 0 0;
    gap: 1.5rem;
    height: auto;
}
.homeinsight_card_r1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.homeinsight_card_l1 {
    width: 100%;
    display: flex;
    border: 0.97px solid #9C9C9C;
    border-radius: 13.52px;
    flex-direction: column;
}
.HI_l_img {
    width: 100%;
    height: auto;
    position: unset;
}
.HI_l_img_div{
    width: 100%;
    position: unset;
}
.HI_l_del_div {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: unset;
    text-align: justify;
    width: 100%;
}
.HI_title{
    font-size: 24.97px;
    padding-top: 0;
}
.homeinsight_base_wrapp {
    width: 90%;
}
.homeinsight_base_wrapp>h1 {
    font-size: 40px;
    line-height: 53px;
}
.homeinsight_base_wrapp > p {
    text-align: justify;
}
.text-overlay {
    font-size: 21px;
}
  }
  @media (min-width: 481px) and (max-width: 768px) {

    .homeinsight_card_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* height: 50vh; */
        margin: 3rem 0 0 0;
        gap: 1.5rem;
        height: auto;
    }
    .homeinsight_card_r1 {
        width: 380px;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin: auto;
    }
    .homeinsight_card_l1 {
        width: 100%;
        display: flex;
        border: 0.97px solid #9C9C9C;
        border-radius: 13.52px;
        flex-direction: column;
    }
    .HI_l_img {
        width: 100%;
        height: auto;
        position: unset;
    }
    .HI_l_img_div{
        width: 100%;
        position: unset;
    }
    .HI_l_del_div {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: unset;
        text-align: justify;
        width: 100%;
    }
    .HI_title{
        font-size: 24.97px;
        padding-top: 0;
    }
    .homeinsight_base_wrapp {
        width: 90%;
    }
    .homeinsight_base_wrapp>h1 {
        font-size: 40px;
    }
    .homeinsight_base_wrapp > p {
        text-align: justify;
    }
    .text-overlay {
        font-size: 21px;
    }

  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .HI_l_del_div {
        padding: 10px 10px 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
    }
    .HI_l_img {
        width: 100%;
        height: 476px;
    }
    .homeinsight_card_div {

        height: 476px;
    }
    .HI_r_img {
        width: 100%;
        height: 221px;
    }
    .HI_title {
        font-size: 21.97px;
        padding-top: 2rem;

    }
    .text-overlay {
        font-size: 19.18px;

    }
  }

  @media (min-width: 1025px) and (max-width: 1198px) {
    .HI_l_del_div {
        width: 45%;
    }
  }
  