.about_service_main_wrapp{
    width: 100%;
    margin: auto;
    padding: 4rem 0 0 0;
    /* margin-top: 3rem; */
}
.about_service_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
    width: 85%;
}
.about_service_title{
    font-family: var(--font-500);
    font-size: 48px;
    font-weight: 500;
    line-height: 70.78px;
    color: var(--color-white);
}



.accordian-main{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #ffffff80;
    cursor: pointer;
    
}
.accordian-main:first-child {
    border-top: none;
  }
  .accordian-main.open .btn {
    display: none; /* Hides the button when the accordion is open (hovered) */
  }
.title-btn-div{
    display: flex;
    width: 100%;
    align-items: center;
    align-items: center;
    padding-bottom: 45px;
    padding-top: 45px;
}
.title-btn-div .title{
    width: 90%;
}
.title-btn-div .title p{
    color: var(--color-white);
    font-family:var(--font-400);
    font-size: 38px;
    font-style: italic;
    font-weight: 600;
    line-height: 30px; /* 150% */
    width: 95%;
}
.title-btn-div .id{
    width: 10%;
}
.title-btn-div .btn{
    width: 10%;
    display: flex;
    justify-content: end;
}
.title-btn-div .btn button{
    border: none;
    background: none;
    -webkit-transition-duration: 0.3s;
    transition: transform 0.3s ease; 
    transition-duration: 0.3s;
    -webkit-transition-property:transform;
}
.btn button:hover{
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.accordian-main.open .title-btn-div {
    padding-bottom: 0px;
}

.accordian-del-p{
    color: var(--color-white);
    font-family: var(--font-400);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.79px;
    width: 100%;
    padding-bottom: 34px;
}
.data-p{
    width: 100%;
}
.btn>button>img{
    width: 40px;
    height: 34px;
}
.accordian_open_div{
    display: flex;
    display: flex;
    width: 90%;
    margin-left: auto;
    flex-direction: column;
}
.accordian_open_div_1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.accordian_open_div_2{
    width: 100%;
    transform: translateY(-43px);
}
.accordian_open_div_1 > img{
width: 36px;
}
.accordian_open_div_1_1{
    width: 42%;
    transform: translateY(57px);
}
.accordian_open_div_1_2 {
    width: 58%;
    display: flex;
    justify-content: end;
}
.accordian_open_div_1_2 >img{
    width: 512px;
    height: 283px;
    border-radius: 19px;
}
.accordian_open_div_2 > button >img{
    width: 36px;
}
.accordian-del {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  @keyframes slideDown {
    0% {
      max-height: 0;
      padding-bottom: 0;
      transform: translateY(-69px);
    }
    100% {
      max-height: 1000px; /* Adjust as needed */
      padding-bottom: 0rem;
      transform: translateY(-69px);
    }
  }
  
  .accordian-del {
    overflow: hidden;
    animation: slideDown 0.3s ease-in-out;
  }
  
  .accordian-del.open {
    animation-name: none; /* Disable the animation for opening */
    max-height: 1000px; /* Adjust as needed */
    padding-bottom: 0rem;
    animation: slideDown 0.3s ease-in-out;
  }
  
@media screen and (max-width: 768px) {
    .home-question-wrap {
        max-width: 1280px;
        padding-left: 0px;
        padding-right: 0px;
        margin: auto;
        width: 90%;
    }
    .home-question-div {
        flex-direction: column;
        gap: 20px;
    }
    .home-question-sec1 {
        width: 100%;
    }
    .home-question-sec2 {
        width: 100%;
    }.accordian-del p {
        width: 100%;
        text-align: justify;
    }
    .title-btn-div .title p {
        font-family: var(--font-base);
        font-size: 23px;
    
    }
    .contents .head {
        position: unset;
        width: 100%;
        height: 122px;
        display: flex;
    }
    .question-img {
        position: unset;
        width: 130px;
        height: 100px;
        transform: translateY(-31px);
    }
    .contents .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 36px;
        position: relative;
        z-index: 1;
    }
    .contents .des {
        padding-top: 0px;
        width: 100%;
        text-align: justify;

    }
    .home-question-sec1 .contents {
        padding-top: 0;
    }
    .accordian_open_div_1 {
        flex-direction: column;

    }
    .accordian_open_div_1_1 {
        width: 100%;
        transform: translateY(0px);
    }
    .accordian_open_div_1_2 {
        width: 100%;
        justify-content: start;
    }
    .accordian_open_div {
        gap: 2.5rem;
    }
    .accordian_open_div_2 > button >img {
        width: 27px;
    } 
    .accordian_open_div_2{
        transform: translateY(0px);
    }
    .accordian-del.open {
        max-height: 1000px; /* Adjust as needed */
        padding-bottom: 2.5rem;
        transform: translateY(0);
      }
      .title-btn-div {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .btn>button>img {
        width: 34px;
        height: 23px;
    }
    .accordian-main.open .title-btn-div {
        padding-bottom: 25px;
    }
   .about_service_title{
    font-size: 35px;
   }
   .about_service_base_wrapp {
    max-width: var(--max-width);
    margin: auto;
    width: 90%;
}
.accordian_open_div_1_2 >img {
    width: 100%;
    height: 100%;
    border-radius: 19px;
}
}

@media (min-width: 768px) and (max-width: 1024px){ 
    .home-question-wrap {
        max-width: 1280px;
        padding-left: 0px;
        padding-right: 0px;
        margin: auto;
        width: 90%;
    }
    .home-question-div {
        flex-direction: column;
        gap: 20px;
    }
    .home-question-sec1 {
        width: 100%;
    }
    .home-question-sec2 {
        width: 100%;
    }.accordian-del p {
        width: 100%;
        text-align: justify;
    }
    .title-btn-div .title p {
        font-family: var(--font-base);
        font-size: 23px;
    
    }
    .contents .head {
        position: unset;
        width: 100%;
        height: 122px;
        display: flex;
    }
    .question-img {
        position: unset;
        width: 130px;
        height: 100px;
        transform: translateY(-31px);
    }
    .contents .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 36px;
        position: relative;
        z-index: 1;
    }
    .contents .des {
        padding-top: 0px;
        width: 100%;
        text-align: justify;

    }
    .home-question-sec1 .contents {
        padding-top: 0;
    }
    .accordian_open_div_1 {
        flex-direction: column;

    }
    .accordian_open_div_1_1 {
        width: 100%;
        transform: translateY(0px);
    }
    .accordian_open_div_1_2 {
        width: 100%;
        justify-content: start;
    }
    .accordian_open_div {
        gap: 2.5rem;
    }
    .accordian_open_div_2 > button >img {
        width: 27px;
    } 
    .accordian_open_div_2{
        transform: translateY(0px);
    }
    .accordian-del.open {
        max-height: 1000px; /* Adjust as needed */
        padding-bottom: 2rem;
        transform: translateY(0);
      }
      .title-btn-div {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .btn>button>img {
        width: 34px;
        height: 23px;
    }
    .accordian-main.open .title-btn-div {
        padding-bottom: 25px;
    }
   .about_service_title{
    font-size: 35px;
   }
   .about_service_base_wrapp {
    max-width: var(--max-width);
    margin: auto;
    width: 90%;
}
}

@media (min-width: 1025px) and (max-width:1211px){
    .home-question-div {
        gap: 24px;
    }
    .contents .head {
        width: 100%;
    }
    .contents .title {

        font-size: 31px;
        line-height: 43px;
    }
    .question-img {
        top: -32px;
        width: 167px;
    }
}

/* homequestions.css */

/* Define keyframe animation for accordion opening */
/* homequestions.css */

/* Define transition effect for the accordion content */

  
  /* Set max-height to a large enough value to accommodate the content when accordion is open */

  .accordian_open_div_2 > img{
    
  }
  
  /* Define animation for opening the accordion */
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Define animation for closing the accordion */
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  /* Apply animations to accordion content */
  .accordian-del.open {
    animation: slideDown 0.3s ease-out;
  }
  .accordian-del {
    animation: slideUp 0.3s ease-out;
  }
  
  /* Other styles remain unchanged */

  @keyframes slideUp1 {
    0% {
      max-height: 1000px; /* Adjust as needed */
    }
    100% {
      max-height: 0;
    }
  }
  
  .accordian-del.closed {
    animation: slideUp1 0.5s ease forwards;
  }