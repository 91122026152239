.cs_webpage_main_wrapp{
    width: 100%;
    margin: auto;
    background: #FAFAFA;
    padding: 5rem 0;
}
.cs_webpage_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.cs_webpage_content_div{
    display: flex;
    margin-top: 4rem;
}
.cs_webpage_content_div_left{
    width: 60%;
}
.cs_webpage_content_div_right{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cs_webpage_content_div_right > div{
    width: 60%;
}
.cs_webpage_content_div_right >div>h1{
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    color: var(--color-black);
}
.cs_webpage_content_div_right >div >p{
    color: var(--color-black);
    font-size: 20px;
    font-weight: 500;
    line-height: 27.2px;
    
}
.cs_webpage_content_div2{
    display: flex;
    margin-top: 8rem;
    margin-left: 4%;
    padding-bottom: 3rem;
}
.cs_webpage_mob_div{
    position: relative;
    height: 70vh;
    display: flex;
    justify-content: center;
}
.cs_web_mob1{
    position: absolute;
    height: 581px;
    bottom: 0;
}
.cs_web_mob2{
    position: absolute;
    height: 520px;
    bottom: 0px;
    left: 46%;

}
.cs_webpage_content_div_left2{
    width: 50%;
}
.cs_webpage_content_div_right2{
    width: 50%;
    display: flex;
    align-items: center;
}
.cs_webpage_content_div_right2 > div{
    width: 60%;
    margin-left: 4%;
}
.cs_webpage_content_div_right2 >div>h1{
    font-size: 32px;
    font-weight: 700;
    line-height: 38.73px;
    color: var(--color-black);
}
.cs_webpage_content_div_right2 >div >p{
    color: var(--color-black);
    font-size: 20px;
    font-weight: 500;
    line-height: 27.2px;
    
}

@media(max-width: 767px){
    .cs_webpage_base_wrapp {
        width: 90%;
    }
    .cs_webpage_main_wrapp {
        width: 100%;
        margin: auto;
        background: #FAFAFA;
        padding: 1rem 0 5rem 0;
    }
    .cs_webpage_content_div {
        flex-direction: column-reverse;
        gap: 2rem;
    }
    .cs_webpage_content_div_right {
        width: 100%;

    }
    .cs_webpage_content_div2 {
        flex-direction: column;
        margin-top: 4rem;
        gap: 2rem;
        margin-left: 0;
        padding-bottom: 0;
    }
    .cs_webpage_content_div_right > div {
        width: 100%;
    }
    .cs_webpage_content_div_right2 {
        width: 100%;
    }
    .cs_webpage_content_div_left2 {
        width: 100%;
    }
    .cs_webpage_content_div_right2 > div {
        width: 100%;
        margin-left: 0;
    }
    .cs_webpage_mob_div {
        position: unset;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    .cs_web_mob1, .cs_web_mob2 {
        position: unset;
        height: auto;
        max-width: 380px;
        max-height: 352px;
        object-fit: contain;
    }
    .cs_webpage_content_div_left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
    }
 
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cs_webpage_content_div_right > div {
        width: 100%;
    }
    .cs_webpage_content_div_left {
        width: 50%;
    }
    .cs_webpage_content_div_right {
        width: 50%;
        display: flex;
        align-items: center;
    }
    .cs_webpage_content_div_right2 {
        width: 50%;
    }
    .cs_webpage_content_div_left2 > div {
        width: 100%;
        /* margin-left: 4%; */
        height: 475px;
        justify-content: end;
    }
    .cs_webpage_content_div_right2 > div {
        width: 100%;
        margin-left: 4%;
    }
    .cs_webpage_content_div_left2 {
        width: 50%;
    }
    .cs_web_mob2 {
        right: 0;
        left: 55%;
        height: 475px;
    }    
    .cs_web_mob1 {
        /* right: 48%; */
        height: 475px;
    }
    .cs_webpage_content_div2 {
        display: flex;
        margin-top: 0;
        margin-left: 0;
        width: 90%;
        margin: auto;
    }
    .cs_appmap_circle2 {
        position: relative;
        width: auto;
        height: 518px;
        justify-content: flex-start;
    }
    .cs_webpage_content_div_right2 >div >p {
        max-width: 350px;
    }
    .cs_webpage_content_div_right >div >p {
        max-width: 350px;
    }
    .cs_webpage_content_div {
        width: 90%;
        margin: 4rem auto auto;
    }
}