.CW_main_wrapp{
    width: 100%;
    margin: auto;
    padding: 5rem 0;
    height: 100%;

}
.CW_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.CW_base_wrapp >h1{
    font-family: var(--font-500);
    font-size: 48px;
    font-weight: 500;
    line-height: 70px;
    text-align: left;  
}
.CW_content_div >p{
    font-family: var(--font-100);
    font-size: 22px;
    font-weight: 400;
    line-height: 22.79px;
    text-align: left;
    
}
.CW_content_div{
    padding: 2rem 0 2rem 0;
}
.apply_arrow_career{
    color: var(--color-white);
    font-size: 25px;
    font-family: var(--font-300);
    margin: auto;
    text-align: center;
    padding-top: 23px;
    border: 4px solid #808080c9;
  }
  .border1{
    border: none;
  }
/* click here apply button */
  .clickheretoapply-button {
    width: 177px;
    height: 59px;
    /* box-shadow: 16px 14px 20px #0000008c; */
    border-radius: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
}
.clickheretoapply-button::before{
  content: "";
  background-image: conic-gradient( #595CFC 20deg, transparent 120deg );
  width: 198%;
  height: 326%;
  position: absolute;
  animation: rotate 3s linear infinite 
}
.clickheretoapply-button::after{
  content: "Click here to apply";
  width: 172px;
  height: 54px;
  background-color: var(--color-black);
  position: absolute;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-size: 18px;
  font-family: var(--font-300);
}
.clickheretoapply-button:hover {
  background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
  transition: 0.5s all;
}
.clickheretoapply-button:hover::after {
  background-color: transparent;
  color: var(--color-white);
  /* transition: 0.5s all; */
}
.clickheretoapply-button:hover::before {
  background-image: none;
  /* transition: 0.5s all; */
}


@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-360deg);
    }
}

  @media (max-width: 767px){
    .CW_base_wrapp {
        width: 90%;
    }
    .CW_base_wrapp >h1 {
        font-size: 35px;
        line-height: 45.39px;
    }
    .apply_arrow_career{
      padding-top: 13px;
      font-size: 18px;
    }
    .CW_content_div >p {
        text-align: justify;
        font-size: 19px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
.CW_base_wrapp {

  width: 90%;
}
}