.homehero_main_wrapp {
  width: 100%;
  min-height: 125vh;
  background: var(--color-black);
  position: relative;
}

.homehero_text_sec_wrapp {
  position: absolute;
  width: 100%;
  min-height: 100px;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.homehero_text_sec_wrapp > h1 {
  font-size: 75px;
  font-family: var(--font-aeoniktrial) !important;
  font-weight: 400;
  color: var(--color-white);
  line-height: 110%;
}

.home_hero_contect_btn_wrapp > p,
.homehero_grid_text,
.homehero_text_des {
  font-family: var(--font-gill);
  color: var(--color-white);
}

.homehero_text_des {
  font-size: 1.6rem;
  line-height: 150%;
  margin: 10px auto;
}

.home_hero_contect_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.home_hero_contect_btn_wrapp > p {
  font-size: var(--font-18);
  line-height: 21.59px;
  text-align: center;
}

.homehero_grid_wrapp {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 1fr);
  gap: 10px;
  padding: 10px;
  z-index: 111;
  margin-top: 11px;
  width: 60%;
  min-width: 400px;
  min-height: 195px;
  left: 50%;
  top: 34%;
  border-radius: 8px;
}

.homehero_grid_wrapp > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homehero_grid_wrapp > div {
  overflow: hidden;
  position: relative;
}

.homehero_grid_1 {
  grid-column: 1/2;
  grid-row: 1/4;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.homehero_grid_2 {
  grid-column: 1/2;
  grid-row: 4/-1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.homehero_grid_3,
.homehero_grid_4 {
  grid-column: 2/3;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.homehero_grid_3 {
  grid-row: 1/5;
}

.homehero_grid_4 {
  grid-row: 5/-1;
}

.homehero_grid_5 {
  grid-column: 3/4;
  grid-row: 1/-1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.homehero_grid_6,
.homehero_grid_7 {
  grid-column: 4/-1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.homehero_grid_6 {
  grid-row: 1/6;
}

.homehero_grid_7 {
  grid-row: 6/-1;
}

.homehero_grid_text {
  font-size: var(--font-24);
  position: absolute;
  font-weight: 400;
}

.inovetive_text {
  top: 10%;
  left: 10%;
}

.strategic_text,
.tenacious_text {
  bottom: 10%;
  left: 10%;
}

.hh_btn_link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 395px) {
  .homehero_text_sec_wrapp > h1 {
      font-size: 55px;
      line-height: 78px;
      margin-bottom: 2rem;
  }

  .homehero_grid_text {
      font-size: 16px;
  }

  .homehero_grid_wrapp {
      top: 59%;
  }

  .homehero_main_wrapp {
      width: 99%;
      min-height: 105vh;
      background: var(--color-black);
      position: relative;
      margin: auto;
  }
}

@media (min-width: 396px) and (max-width: 768px) {
  .homehero_text_sec_wrapp > h1 {
      font-size: 55px;
      line-height: 78px;
      margin-bottom: 2rem;
  }

  .homehero_grid_text {
      font-size: 16px;
  }

  .homehero_grid_wrapp {
      top: 59%;
  }

  .homehero_main_wrapp {
      width: 99%;
      min-height: 93vh;
      background: var(--color-black);
      position: relative;
      margin: auto;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .homehero_main_wrapp {
      width: 100%;
      min-height: 94vh;
      background: var(--color-black);
      position: relative;
  }

  .homehero_grid_wrapp {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(8, 1fr);
      gap: 10px;
      padding: 10px;
      z-index: 111;
      width: 81%;
      min-width: 424px;
      min-height: 251px;
      left: 50%;
      top: 35%;
      border-radius: 8px;
  }
}
