.comingsoon_div{
    margin-top: 92px;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width: 768px) {
    .comingsoon_div{
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}