.jb_descrip_main_wrapp{
    width: 100%;
    background-color: var(--color-white);
    margin: auto;
    padding: 4rem 0;
}
.jb_descrip_base_wrapp{
    max-width: var(--max-width-md);
    margin: auto;
}
.jb_descrip_base_wrapp > h1{
    font-size: 20.66px;
    font-weight: 600;
    line-height: 22.03px;
    letter-spacing: -0.11475564539432526px;
    text-align: left;
    color:#191B22;
}
.jb_descrip_base_wrapp > h2{
    font-size: 16.07px;
    font-weight: 400;
    line-height: 31.21px;
    letter-spacing: -0.11475564539432526px;
    text-align: left;
    color:  #50566D;
    margin: 1rem 0;
}
.jb_des>ul,.jb_skill>ul {
    list-style-type: disc; 
    padding-left: 20px; 
  }
.jb_des >ul>li, 
.jb_skill >ul>li{
    font-size: 16.07px;
    font-weight: 400;
    line-height: 31.21px;
    letter-spacing: -0.11475564539432526px;
    text-align: left;
    color:  #50566D;
    margin-bottom: 7px;
}
.jb_descrip_base_wrapp >a> button{
    background: linear-gradient(90deg, #5864F9 0%, #24D2DA 100%);
    height: 56.45px;
    border-radius: 110px;
    padding: 0 2rem;
    font-size: 20.66px;
    font-weight: 600;
    line-height: 31.21px;
    text-align: center;
    
}
.jb_des,.jb_skill{
    padding-left:12px;
}

@media (max-width: 768px) {
    .jb_descrip_base_wrapp{
        width: 90%;
    }
    .jb_des >ul>li, .jb_skill >ul>li {
        text-align: justify;
    }
    .jb_des, .jb_skill {
        padding-left: 0;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .jb_descrip_base_wrapp{
        width: 90%;
    }
}