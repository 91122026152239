.tech_banner_main_wrapp{
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    margin-top: 92px;
}
.tech_banner_base_wrapp {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0); /* Initial background color */
    transition: background 2s ease; /* Smooth transition for background change */
}


.tech_banner_base_wrapp.zoomed {
    animation: gradientChange 2s forwards;
  }
  
  @keyframes gradientChange {
    from {
      background:rgb(0, 0, 0);
    }
    to {
      background:  linear-gradient(178.03deg, #000000 1.66%, rgba(88, 111, 247, 0.4) 192.59%);
    }
  }




.build-text, .tech-text {
  position: absolute;
  transition: all 2s ease;
}

.build-text {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tech-text {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

.build-move {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.tech-move {
  bottom: 60%;
  right: 50%;
  transform: translate(50%, 50%);
}

  /* .main_wrapp_tech {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    overflow-x: hidden;
  } */
  .bd_main_wrapp_tech {
    width: var(--max-width);
    /* height: 80vh; */
    height: 23vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--color-black); */
    position: relative;
    color: #ffff;
  
  }
  .bd-parallexLayer{
    height: 432!important;
  }
  
  .bd_base_wrapp_tech::-webkit-scrollbar {
    display: none;
  }
  .bd_base_wrapp_tech {
    width: var(--max-width);
    /* margin: 2rem auto; */
    height: 100%;
  }
  

  

  .nonsticky-dream{
      /* width: 50%; */
      /* height: 500px; */
      height: 269px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 75px;
  }
  .build-text,.tech-text
  {
    font-size: 107px;
    font-weight:800;
    line-height: 199.4px;
    font-family:var(--font-inter);
  }
  .bd-parallexLayer-non_tech{
    justify-content: center!important;
    border: none!important;
  }
  .stickyp{
    font-family: var(--font-inter);
    font-size: 13.6px;
    font-weight: 500;
    line-height: 16.47px;
    text-align: center;
    color: var(--color-white);
    padding: 0 20px;
  }

  .tech_banner_base_wrapp {
    position: relative;
    overflow: hidden;
  }
  


  
  .main_wrapp_tech {
    position: relative;
    height: 16vh; /* Full viewport height */
    overflow: hidden;
    /* max-width: 704px; */
  }
  
  .build-text, .tech-text {
    position: absolute;
    transition: all 2s ease;
  }
  
  .build-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .tech-text {
    bottom: -62px;
    right: 0;
    transform: translate(50%, 50%);
  }
  
  .build-move {
    top: -82px;
    left: 0;
    transform: translate(-50%, -50%);
  }
  
  .tech-move {
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
  }

  .tech_content_div {
    width: 55%;
    margin: auto;
}
  @media (max-width: 768px) {

   
 
  
  }
 