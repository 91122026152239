.contact_location_main_Wrapp {
    width: 100%;
    margin: auto;
    position: relative;
    max-width: var(--max-width);
}

.blue_back_img {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -2;
    top: -3rem;
    /* transform: translateX(50%); */

}

.contact_loc_title_div {
    max-width: var(--max-width);
    margin: auto;
    padding: 5rem 0 2rem 0;
}

.contact_loc_title_div>h1 {
    font-family: var(--font-700);
    font-size: 115px;
    font-weight: 700;
    line-height: 78px;
    text-align: left;

}

.c_l_title2 {
    padding-top: 4rem;
    margin-left: 13rem;
}

.contact_loc_del_div {}

.contact_loc_del_card {
    display: flex;
    height: auto;
    border-bottom: 1px solid grey;
    padding: 2rem 0;
    /* height: 328px; */
    height: 360px;
}

.contact_loc_del_card:last-child {
    border-bottom: none;
    /* padding-bottom: 5rem; */
}

.contact_loc_img_div_main {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_loc_address_div>p{
    max-width: 194px;
}

.contact_loc_img_div {
    height: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #767676;
    text-align: center;
    font-family: var(--font-gill);
    font-weight: bold;
    font-size: 3em;
    flex-direction: column;
    gap: 0.4em;
    padding: 2rem;
    /* margin-top: 0.3em; */
}

.contact_loc_img_div>p {
    font-size: xx-large;
}

.contact_loc_img_div>img {
    width: 45px;
    height: 45px;
}

.contact_loc_address_div {
    width: 33%;
    padding: 0rem 2rem 0rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact_loc_contact_div {
    width: 32%;
    padding: 0 2rem 0 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21px;
}

.contact_loc_map_div {
    width: 20%;
    padding: 78px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
    position: relative;
}

.contact_loc_address_div>h1 {
    font-family: var(--font-gill);
    font-size: 33.34px;
    font-weight: 500;
    line-height: 40.01px;
    text-align: left;
}

.contact_loc_address_div>h3,
.contact_loc_contact_div>h3,
.contact_loc_map_div>h3 {
    font-family: var(--font-gill);
    font-size: 24.34px;
    font-weight: 400;
    line-height: 40.01px;
    letter-spacing: 0.15em;
    text-align: left;
    color: #8D8D8D;

}

.contact_loc_address_div>p,
.contact_loc_contact_div>p {
    font-family: var(--font-gill);
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.contact_loc_contact_div>p>a{
    word-break: break-all;
}
.location_btn {
    color: #24D2DA;
    font-family: var(--font-gill);
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.location_btn {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.location_btn>img {
    width: 21px;
    height: 21px;
}
.contact_loc_del_card:last-child .contact_loc_contact_div {
    margin-top: 45px;
  }
  .contact_loc_del_card:last-child .contact_loc_map_div {
    margin-top: 20px;
  }
@media screen and (max-width: 767px) {
    .contact_loc_title_div>h1 {
        font-size: 50px;
        line-height: 33px;
    }

    .blue_back_img {
        position: absolute;
        /* left: 0; */
        width: 100%;
        z-index: -2;
        top: 0;
        transform: translateX(0);
    }

    .c_l_title2 {
        padding-top: 1rem;
        margin-left: 6rem;
    }

    .contact_loc_del_card {
        display: flex;
        height: auto;
        flex-direction: column;
        gap: 1.5rem;
    }

    .contact_loc_address_div,
    .contact_loc_contact_div,
    .contact_loc_map_div {
        width: 100%;
        padding: 0 2rem;
        gap: .5rem;
    }

    .contact_loc_map_div {
        align-items: start;
    }

    .contact_loc_img_div_main {
        width: 100%;
    }

    .contact_loc_img_div {
        width: 85%;
    }
    .contact_loc_address_div>h1
    {
        padding-top: 20px;
    }
    .contact_loc_del_card:last-child {
        border-bottom: none;
        padding-bottom: 5rem;
    }
    .contact_loc_address_div>h1 {
        font-size: 30px;
    }
    .contact_loc_address_div>h3, .contact_loc_contact_div>h3, .contact_loc_map_div>h3 {
        font-size: 20.34px;
        line-height: normal;
    }
    .contact_loc_address_div>p, .contact_loc_contact_div>p {
        font-family: var(--font-gill);
        font-size: 19px;
    }
    .contact_loc_title_div {
        padding: 5rem 0 0rem 0;
    }
    .contact_loc_address_div>p {
        max-width: 170px;
    }
    .contact_loc_contact_div{
        margin-top: 0;
    }
    .contact_loc_del_card:last-child .contact_loc_contact_div {
        margin-top: 0px;
      }
      .contact_loc_del_card:last-child .contact_loc_map_div {
        margin-top: 0px;
      }
}
@media (min-width: 768px) and (max-width:1024px){
    .contact_loc_address_div {
        padding: 2rem 1rem 3rem 3rem;
        max-width: 231px;
    }
    .contact_loc_contact_div {
        padding: 2rem 2rem 3rem 2rem;
        width: 28%;
        gap: 8px;
        justify-content: center;
    }
    
    .contact_loc_map_div {
        padding: 2.5rem 0;
        padding-top: 72px;
    }
    .contact_loc_title_div {
        width: 90%;
    }
    .contact_loc_del_div {
        width: 90%;
        margin: auto;
    }
    .contact_loc_address_div>p, .contact_loc_contact_div>p {
        font-size: 19px;

    }
    .contact_loc_address_div>h3, .contact_loc_contact_div>h3, .contact_loc_map_div>h3 {
        font-size: 21px;
        line-height: 40.01px;
    }
    .contact_loc_img_div_main {
        width: 19%;
    }
    
}

