/* Service Branding Banner Styles */

.ser_branding_banner_main_Wrapp {
    width: 100%;
    margin: auto;
    background: linear-gradient(358.03deg, rgba(88, 103, 249, 0) -47.26%, #000000 33.6%, #000000 119.15%);
    height: 90vh;
    background-color: #5867F9;
    margin-top: 92px;
    display: flex; /* Use Flexbox to align the child element */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
}

.ser_branding_banner_base_wrapp {
    max-width: var(--max-width);
    margin: auto;
    display: flex; /* Use Flexbox to align the child element */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
}

.ser_bb {
    text-align: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ser_bb > h1 {
    font-family: var(--font-aeoniktrial)!important;
    font-size: 96px;
    font-weight: 600;
    line-height: 116.18px;
    color: var(--color-white);
    margin: 0; /* Remove default margin */
}

.ser_bb > h2 {
    font-size: 96px;
    font-weight: 400;
    line-height: 127.97px;
    color: var(--color-white);
    margin: 0; /* Remove default margin */
}
.ser_bb >img{
    width: 138px;
    margin-top: 2.5rem;
}

@media(max-width: 768px){
    .ser_branding_banner_main_Wrapp {
        height: 70vh;
    }
    .ser_branding_banner_base_wrapp {
        width: 90%;
    }
    .ser_bb > h1 {
        font-family: var(--font-aeoniktrial)!important;
        font-size: 55px;
        font-weight: 600;
        line-height: 62px;
        color: var(--color-white);
        margin: 0; /* Remove default margin */
    }
    
    .ser_bb > h2 {
        font-size: 50px;
        font-weight: 400;
        line-height: 69.97px;
        color: var(--color-white);
        margin: 0; /* Remove default margin */
    }
    .ser_bb > img{
        margin-top: 1rem;
    }
    .sb_contact_card {

        gap: 1rem;
    }
    .ser_bb >img{
        width: 110px;
        margin-top: 2.5rem;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .ser_branding_banner_main_Wrapp {
        height: 60vh;
    }
    .ser_bb > h1,
    .ser_bb > h2 {
        font-size: 55px;
        line-height: normal;
    }
    .ser_bb >img {
        width: 100px;
    }
}    