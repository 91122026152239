.CS_project_main_wrapp{
    width: 100%;
    margin: auto;
    background-color: var(--color-white);

    padding: 5rem 0;
}
.CS_project_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.CS_project_top{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 8rem;
}
.CS_project_top >hr{
    width: 99%;
    border: 3px solid #F2F2F2
}
.CS_project_top >img{
    /* height: 125px; */
    width: 125px;
    position: absolute;
    right: 35%;
}
.CS_project_div1 {
    width: 488px;
    padding: 2rem 0;
    margin-left: 4%;
}
.CS_project_div1 >h2{
    font-family: var(--font-inter);
    font-size: 49.66px;
    font-weight: 700;
    color: var(--color-black);
}
.CS_pro{
    font-style: italic;
    font-weight: 400;
    
}
.CS_project_div1 >p{
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
    line-height: 21.76px;
    color: var(--color-black);
    margin-top: 1rem;
}
.CS_project_div2{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    min-height: 260px;
}
.CS_project_div2_left{
    display: flex;
    background: #FAFAFA;
    border-radius: 40px;
    width: 50%;
    flex-direction: column;
    justify-content: end;
    align-items: baseline;
    padding: 2rem 3rem;
    gap: 1rem;
}
.CS_project_div2_left >h2{
    font-family: var(--font-inter);
    font-size: 28px;
    font-weight: 500;
    line-height: 33.89px;
    text-align: center;
    color: var(--color-black);
}
.CS_project_div2_left>p{
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    line-height: 21.76px;
    text-align: left;
    color: var(--color-black);
}
.CS_project_div2_right{
    background:var(--color-black) ;
    border-radius: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: baseline;
    padding: 2rem 3rem;
    gap: 1rem;
}
.CS_project_div2_right >div >ul{
    list-style-type: disc; 
    margin: 0;             
    padding: 0 0 0 20px;
}
.CS_project_div2_right >h2{
    font-family: var(--font-inter);
    font-size: 28px;
    font-weight: 500;
    line-height: 33.89px;
    text-align: center;
    
}
.CS_project_div2_right >p{
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    line-height: 21.76px;
    text-align: left;
}


@media (max-width: 767px) {
    .CS_project_top >img {
        /* height: 103px; */
        width: 75px;
        right: unset;
    }
    .CS_project_base_wrapp {
        width: 90%;
    }
    .CS_project_top >hr {
        width: 98%;
        border: 3px solid #F2F2F2;
    }
    .CS_project_div1 {
        width: auto;
        text-align: justify;
        margin-left: 0;
    }
    .CS_project_div2 {
        flex-direction: column;
    }
    .CS_project_div2_left ,.CS_project_div2_right{
        width: 100%;
    }
    .CS_project_div1 >h2 {
        font-size: 35.66px;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .CS_project_top >img {
        /* height: 103px; */
        width: 75px;
        right: unset;
    }
    .CS_project_base_wrapp {
        width: 90%;
    }
    .CS_project_top >hr {
        width: 98%;
        border: 3px solid #F2F2F2;
    }
    .CS_project_div1 {
        width: auto;
        text-align: justify;
        margin-left: 0;
    }
    .CS_project_div2 {
        flex-direction: column;
    }
    .CS_project_div2_left ,.CS_project_div2_right{
        width: 100%;
    }
}