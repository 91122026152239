.CL_main_wrapp{
    width: 100%;
    margin: auto;
    background: #B5C0C9;
    padding: 5rem 0;
}
.CL_title1{
    font-family: var(--font-500);
    font-size: 150px;
    font-weight: 600;
    line-height: 78px;
    text-align: left;
    max-width: var(--max-width);
    margin: auto;
}

.CL_title2{
    font-family: var(--font-500);
    font-size: 150px;
    font-weight: 600;
    line-height: 78px;
    text-align: right;
    max-width: var(--max-width);
    margin: auto;
}
.CL_slider_div{
    margin: 2rem auto 1px auto;
}
.CL_slider_image{
    /* width: 287px;
    height: 383px; */
    border-radius: 46.71px;
    padding: 1rem 13px;
}
.CL_slider_div {
    position: relative;
    width: 100%;
}

.CL_slide {
    position: relative;
    padding: 10px 0;
    transition: transform 0.5s;
}

.CL_slide.top {
    transform: translateY(9px);
}

.CL_slide.bottom {
    transform: translateY(94px);
}

.CL_slider_image {
    width: 100%;
    height: 355px;
    object-fit: cover;
}
.CL_slider .slick-track {
    height: 518px;
}
.CL_base_wrapp{
    display: block;
}
.CL_base_wrapp_mob{
    display: none;
}

@media(max-width: 768px){
    .CL_title1{
        font-size: 54px;
        line-height: 52px;
        width: 90%;
        padding-bottom: 1rem;   
    }
    .CL_title1 >h1{
        font-family: var(--font-700);
    }
    .CL_slide.top {
        transform: translateY(0);
    }
    
    .CL_slide.bottom {
        transform: translateY(0);
    }
    .CL_slider_div {
        margin: 0rem auto;
    }
    .CL_slider_image {
        width: 100%;
        height: 533px;
        padding: 0rem 13px;
        object-fit: cover;
    }
    .CL_slider .slick-track {
        height: auto;
    }
    .CL_base_wrapp{
        display: none;
    }
    .CL_base_wrapp_mob{
        display: block;
    }
}

@media (min-width: 768px) and (max-width:1024px){
    .CL_title1 {
        font-family: var(--font-500);
        font-size: 110px;
    }
    .CL_title2 {
        font-family: var(--font-500);
        font-size: 110px;
    }
    .CL_slider .slick-track {
        height: 500px;
    }
}