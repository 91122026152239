.cs_wp_main_wrapp{
    padding: 5rem 0;
    max-width: 1280px;
    margin: auto;
}
.cs_wp_base_wrapp{

}
.cs_wp_base_wrapp>h1{
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
    margin-left: 4%;
}
.cs_wp_base_wrapp >h1 >span{
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;
    text-align: center;
    
}
.cs_wp_cards_div{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 23px;
    /* max-width: 994px; */
    margin: 4rem 4% auto 4%;
}  
  .cs_wp_card {
    padding: 16px; 
    border: 1px solid #404040; 
    border-radius: 25px; 
    /* width: 481px; */
    min-height: 148px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    background: #FFFFFF1A;
    transition: transform 0.5s ease, background 0.5s ease;

  }
  .cs_wp_card_left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    gap: 1rem;
  }
  .cs_wp_card_left >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 20.2px;
    text-align: left;
    
  }
  .cs_wp_card_left >h1{
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    
  }
  .cs_wp_card_right{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cs_wp_card_right>h1{
    font-size: 96px;
    font-weight: 400;
    line-height: 116.18px;
    text-align: center;
    
  }

  .cs_wp_card:hover {
    transform: rotate(3deg); /* Slight rotation */
    background: linear-gradient(90deg, rgba(63, 227, 245, 0.65) 0%, #4EC3F9 36.5%, #59ADFB 73.5%, #5285E0 100%); /* Gradient background on hover */
    animation: rotateAnimation 0.5s ease forwards;
  }
  .cs_wp_card_left >ul{
    list-style-type: disc;
    padding-left: 1rem;
  }
  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(3deg);
    }
  }

  @media (max-width: 767px) {
    .cs_wp_main_wrapp{
        width: 90%;
    }
    .cs_wp_cards_div {
      grid-template-columns: 1fr;
      margin: 2rem auto 0 auto;

    }
    .cs_wp_base_wrapp>h1{
        margin-left: 0;
        font-size: 35.66px;
    }
    .cs_wp_card_left >h1 {
      font-size: 20px;
    }
    .cs_wp_card_right>h1 {
      font-size: 68px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {

    .cs_wp_card_left >h1 {
      font-size: 20px;
    }
  }
