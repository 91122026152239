.CB_main_wrapp{
    width: 100%;
    margin: auto;
    height: 70vh;
    margin-top: 92px;
    position: relative;
}
.CB_main_wrapp > img{
    width: 100%;
    height: 79%;
    position: absolute;
    top: 29%;

}
.CB_content_div{
    position: relative;
    transform: translate(0%, -50%);
    max-width: 1280px;
    margin: auto;
    z-index: 1;
    top: 50%;
}
.CB_content_div >h1{
    font-family: var(--font-aeoniktrial)!important;
font-size: 64px;
line-height: 78px;
letter-spacing: -1.81px;
text-align: left;
padding-bottom: 2rem;
}
.CB_overlay{
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    width: 100%;
    top: 0;
    position: absolute;
}
.CB_content_div >button{
    font-family: Gill Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: center;
    border: 1px solid white;
    height: 59px;
    border-radius: 120px;
    padding: 1rem 2rem;

}
/* Careers_btn */
.careers_btn {
    width: 177px;
    height: 59px;
    /* box-shadow: 16px 14px 20px #0000008c; */
    border-radius: 120px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
}

.careers_btn::before {
    content: "";
    background-image: conic-gradient(#595CFC 20deg, transparent 120deg);
    width: 198%;
    height: 326%;
    position: absolute;
    animation: rotate 3s linear infinite
}

.careers_btn::after {
    content: "View open position";
    width: 172px;
    height: 54px;
    background-color: #1c1d22;
    position: absolute;
    border-radius: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    font-size: 18px;
    font-family: var(--font-300);
}

.careers_btn:hover {
    background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
    transition: 0.5s all;
}

.careers_btn:hover::after {
    background-color: transparent;
    color: var(--color-white);
    /* transition: 0.5s all; */
}

.careers_btn:hover::before {
    background-image: none;
    /* transition: 0.5s all; */
}

/* Careers_btn */

@media (max-width: 768px) {
    .CB_content_div {
        width: 90%;
        /* transform: translate(0%, 32%);
        top: 0; */
    }
    .CB_content_div >h1{
    font-size: 42px;
    line-height: 50px;
    padding-bottom: 2rem;
    /* width: 343px; */
    }
    .CB_main_wrapp > img {
        height: 70%;
        top: 30%;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
.CB_content_div {
    width: 90%;
}
.CB_content_div >h1 {
    font-size: 55px;
}
}