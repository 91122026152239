.cs_support_main_wrapp {
    width: 100%;
    /* height: 35vh; */
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    padding: 2rem 0;
    min-height: 29rem;

}
.cs_support_base_wrapp {
    position: relative;

    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    width: 90%;
}
.cs_support_back_img {
    width: 436px;
    position: absolute;
    z-index: 0;
}
.cs_support_base_wrapp > h1{
    font-size: 48px;
    font-weight: 600;
    line-height: 58.09px;
    text-align: center;
    color: var(--color-black);
}
.cs_support_base_wrapp > h2{
    color: var(--color-black);
    font-size: 48px;
    font-weight: 400;
    line-height: 65.76px;
    text-align: center;
}
.cs_support_base_wrapp >h3{

    font-size: 48px;
    font-style: italic;
    font-weight: 500;
    line-height: 65.76px;
    text-align: center;
    background: linear-gradient(90deg, rgba(92, 184, 236, 0.7) 0%, #5864F9 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    
}
.cs_result_3rd_div{
    display: flex;
    justify-content: center;
    padding-top: 6rem;

}
.rs_new1,
.rs_new2,
.rs_new3,
.rs_new4,
.rs_new5{
    font-size: 16px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px 13px;
    height: 89px;
    border-radius: 25px;
    border: 2px solid #2A2C2D;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rs_new1 {
    width: 357px;
    transform: rotate(-14deg);
    left: 49px;
    bottom: 37px;
}
.rs_new2 {
    width: 298px;
    transform: rotate(0deg);
    left: 49px;
    bottom: 47px;
}
.rs_new3 {
    width: 311px;
    transform: rotate(21deg);
    left: -33px;
    bottom: 108px;
}
.rs_new4 {
    width: 287px;
    transform: rotate(-16deg);
    left: -60px;
    top: -28px;
}
.rs_new5 {
    width: 251px;
    transform: rotate(14deg);
    left: -66px;
    bottom: 76px;
}
.result_btm_border{
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, #000000 2.5%, #00A0A7 50.5%, #000000 100%);
}
.cs_result_thanks {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 6rem;
}
.cs_result_thanks >h1{
    font-size: 280.19px;
    font-weight: 600;
    line-height: 339.09px;
    text-align: center;
    z-index: 1;
    background: linear-gradient(360deg, rgba(64, 64, 64, 0.11) 20.06%, rgba(250, 250, 250, 0.11) 82.6%); /* Gradient background */
    -webkit-background-clip: text; /* Clips the background to the text */
    -webkit-text-fill-color: transparent;
}



@media(max-width: 767px){
    .cs_support_main_wrapp{
        height: auto;
        padding: 2rem 0;
    }
    .cs_support_base_wrapp > h1 {
        font-size: 30px;
        line-height: 42.09px;
        /* padding-bottom: 1rem; */
    }
    .cs_support_back_img{
        width: 75%;
    }
    .cs_support_base_wrapp {
        padding: 10px 0;
    }
    .cs_support_base_wrapp > h2 {
        font-size: 30px;
        line-height: 42.09px;
        /* padding-bottom: 1rem; */
    }
    .cs_support_base_wrapp > h3 {
        font-size: 30px;
        line-height: 42.09px;
    }
    .cs_result_3rd_div {
        flex-direction: column;
        gap: 2rem;
        padding-top: 3rem;

    }
    .cs_result_thanks {
        margin-top: 3rem;
    }
    .cs_result_thanks >h1 {
        font-size: 90.19px;
        line-height: normal;
    }
    .rs_new1,
    .rs_new2,
    .rs_new3,
    .rs_new4,
    .rs_new5{
        width: 90%;
        max-width: 380px;
        transform: rotate(0deg);
        left: 0;
        bottom: 0;
        margin: auto;
        top: 0;
        height: 78px;
    }
    .result_btm_border {
        margin-top: 1rem;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .cs_support_base_wrapp >h3 {
        font-size: 35px;
        line-height: normal;
    }
    .cs_support_base_wrapp > h2 {
        font-size: 35px;
        line-height: normal;
        margin: 1.5rem 0;
    }
    .cs_support_base_wrapp {
        width: 98%;
    }
    .cs_result_thanks >h1 {
        font-size: 190.19px;
    }
    .cs_support_base_wrapp > h1 {
        font-size: 35px;
        line-height: normal;
    }
}