.contact_banner_main_wrapp{
max-width: var(--max-width);
margin: auto;
margin-top: 8rem;
}
.contact_banner_base_wrapp{
    width: 100%;
}
.contact_banner_base_wrapp >h3{
    font-family: var(--font-500);
    font-size: 40px;
    font-weight: 700;
    line-height: 78px;
    text-align: left;

}
.contact_banner_base_wrapp >h2{
    font-family: var(--font-aeoniktrial);
    font-size: 48px;
    font-weight: 400;
    line-height: 64px;
    text-align: left;
    margin-top: 10px;
}

@media screen and (max-width: 767px) {
    .contact_banner_main_wrapp{
        width: 90%;
    }
    .contact_banner_base_wrapp >h3 {
        font-size: 30px;
    }
    .contact_banner_base_wrapp >h2 {
        font-size: 35px;
        font-weight: 500;
        line-height: 42px;
    }
}
@media (min-width: 768px) and (max-width:1024px){
    .contact_banner_main_wrapp {
        width: 90%;
    }
}