.ser2_whatwedo_main_wrapp{
    width: 100%;
    margin: auto;
    max-width: var(--max-width);
    padding: 5rem 0
}
.ser2_whatwedo_base_wrapp{
    width: 100%;
    text-align: center;
    max-width: 80%;
    margin: auto;
}
.ser2_whatwedo_base_wrapp >h3{
    font-size: 36px;
    font-family: var(--font-inter);
    font-weight: 500;
    line-height: 24.2px;
    color: var(--color-white);
    padding-bottom: 1rem;
}
.ser2_whatwedo_base_wrapp >h2{
    font-size: 32px;
    font-family: var(--font-inter);
    font-weight: 500;
    line-height: 38.73px;
    color: var(--color-white);
}

/* .ser2_cards_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 5rem;
    row-gap: 3rem;
} */
.ser2_cards_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1%;
    margin-top: 5rem;
    row-gap: 3rem;
}

/* .sec2_outer_card{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #30263F;
    height: 323px;
    border-radius: 18px;
    width: 359px;
    margin: auto;
} */
.sec2_outer_card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #30263F;
    height: 354px;
    border-radius: 18px;
    width: 93%;
    margin: auto;
}

/* .ser2_card {
    background: #1E1925;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #30263F;
    border-radius: 8px;
    max-width: 332px;
} */
.ser2_card {
    background: #1E1925;
    border: 1px solid #ddd;
    padding: 26px;
    /* transition: transform 0.3s ease; */
    border: 1px solid #30263F;
    border-radius: 8px;
    max-width: 345px;
    width: 91%;
    display: flex;
    height: 314px;
    flex-direction: column;
    justify-content: space-between;
}

/* .ser2_card:hover {
    transform: translateY(-10px);
} */

.ser2_card img {
    width: 43px;
    height: 56px;
    /* padding-bottom: 15px; */
    margin-bottom: 15px;
}

.ser2_card h2 {
    margin: 15px 0 0;
    font-size: 19.21px;
    font-family: var(--font-inter);
    font-weight: 500;
    line-height: 22.73px;
    color: var(--color-white);
}

.ser2_card p {
    font-size: 32px;
    font-family: var(--font-inter);
    font-weight: 500;
    line-height: 20px;
    color: #9DA8C6;
    font-size: 1rem;
    /* margin-bottom: 20px; */
    min-height: 5.5rem;
}

.ser2_card button {
    background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
    /* height: 33px; */
    border-radius: 51px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 51px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 24px;
    font-family: var(--font-inter);
    max-width: 130px;
}

@media(max-width: 768px){
    .ser2_whatwedo_base_wrapp {
        width: 100%;
        max-width: 90%;
    }
    .ser2_cards_div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        margin-top: 3rem;
        row-gap: 1.7rem;
    }
    .sec2_outer_card {
        height: 323px;
        margin: auto;
        width: 359px;
    }
    .ser2_card {
        padding: 20px;
        height: 275px;
    }
    .ser2_whatwedo_base_wrapp >h2 {
        font-size: 25px;

    }
   
}
@media (min-width: 769px) and (max-width: 1024px) {
    .ser2_cards_div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
        row-gap: 1.7rem;
        max-width: 769px;
        margin: 3rem auto auto auto;
    }
    .sec2_outer_card{
        max-width: 383px;
    }
}