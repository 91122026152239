.home_os_main_wrapp {
  width: 100%;
  /* min-height: 120vh; */
  background: var(--color-white);
  position: relative;
  padding-top: 2rem;

}

.home_os_base_wrapp {
  max-width: var(--max-width);
  margin: auto;
  width: 85%;
}

.home_os_two_sec_wrapp {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 2rem auto;
  gap: 1.5rem;
}

.home_os_two_sec_wrapp_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;

  background: #e9eaed;

  padding: 2rem 1rem;
  border-radius: 8px;
  width: 50%;
}

.sec_heading1{
  font-size: 48px;
  font-weight: 500;
  line-height: 70px;
  text-align: left;
  color: var(--color-black);
}
.home_os_two_sec_wrapp_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--color-black);

  padding: 2rem 1rem;
  border-radius: 8px;
  width: 50%;
  gap: 1rem;
}

.home_os_main_des {
  font-family: var(--font-400);
  font-size: 22px;
  font-weight: 400;
  line-height: 34.48px;
  text-align: left;
  color: var(--color-black);
}

.home_os_two_sec_wrapp_1_first_text {
  font-family: var(--font-500);
  font-size: var(--font-22);
  font-weight: 500;
  letter-spacing: 0.09430292993783951px;
  text-align: left;
  color: var(--color-black);
}

.home_os_two_sec_wrapp_1_sec_text {
  font-family: var(--font-500);
  font-size: var(--font-14);
  font-weight: 500;
  letter-spacing: 0.09430292993783951px;
  text-align: left;
  color: #40434f;
}

.home_os_graph_img {
  width: 100%;
  height: 400px;
  margin: 10px auto;
}

.home_os_two_sec_wrapp_2_center_sec {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home_os_two_sec_wrapp_2_center_sec > div {
  width: 80%;
  background: var(--color-white);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}

.home_os_two_sec_wrapp_2_center_sec > div > p {
  font-family: var(--font-500);
  font-size: var(--font-22);
  font-weight: 500;
  letter-spacing: 0.09430292993783951px;
  text-align: left;
  color: #09090b;
}

.home_os_two_sec_wrapp_2_center_sec > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.home_os_two_sec_wrapp_2_2 {
  background: #1c1d22;
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  border-radius: 8px;
  gap: 5rem;
}

.home_os_two_sec_wrapp_2_2 > button {
  font-family: var(--font-400);
  font-size: var(--font-18);
  font-weight: 400;
  text-align: center;
  background: transparent;
  min-width: 177px;
  min-height: 60px;
  display: flex;
  justify-content: center;
}

.home_os_two_sec_P{
  font-family: var(--font-500);
  font-size: 21px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 29.42px;
  letter-spacing: 0.09px;
}
.home_os_two_sec_wrapp_2_2 >p{
  font-family: var(--font-500);
  font-size: 21px;
  font-weight: 500;
  color: var(--color-white);
  line-height: 29.42px;
  letter-spacing: 0.09px;
}
.home_os_two_sec_wrapp_2_3{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: #e9eaed;
    padding: 2rem 1rem;
    border-radius: 8px;
    width: 49%;
}

/* contactus button */
.contactus-button {
  width: 177px;
  height: 59px;
  /* box-shadow: 16px 14px 20px #0000008c; */
  border-radius: 120px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E4E4E4;
}
.contactus-button::before{
content: "";
background-image: conic-gradient( #595CFC 20deg, transparent 120deg );
width: 198%;
height: 326%;
position: absolute;
animation: rotate 3s linear infinite 
}
.contactus-button::after{
content: "About Us";
width: 172px;
height: 54px;
background-color: #1c1d22;
position: absolute;
border-radius: 120px;
display: flex;
justify-content: center;
align-items: center;
color: var(--color-white);
font-size: 18px;
font-family: var(--font-300);
}
.contactus-button:hover {
background-image: linear-gradient(90deg, #24d2da 0%, #6597ff 100%);
transition: 0.5s all;
}
.contactus-button:hover::after {
background-color: transparent;
color: var(--color-white);
/* transition: 0.5s all; */
}
.contactus-button:hover::before {
background-image: none;
/* transition: 0.5s all; */
}
.home_os_glob_div{
  display: flex;
  height: 500px;
  margin: 2rem 0;
  gap: 1.5rem;
  justify-content: center;
}
.home_os_globs{
  width: 100%;
}
@keyframes rotate {
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(-360deg);
  }
}
@media(max-width: 768px){
  .home_os_base_wrapp {
    width: 90%;
  }
  .sec_heading1 {
    font-size: 35px;
}
  .home_os_two_sec_wrapp {
  flex-direction: column;
  gap: 2rem;
  }
  .home_os_two_sec_wrapp_1 {
    width: 100%;
  }
  .home_os_two_sec_wrapp_2 {
    width: 100%;
  }
  .home_os_two_sec_wrapp_2_2 {
    width: 100%;
    padding: 2.5rem 1rem 2rem 1rem;
    gap: 20px;
    flex-direction: column;
  }
  .home_os_two_sec_wrapp_2_3 {
    width: 100%;
  }
  .home_os_two_sec_wrapp_2_2 >p {
    font-size: 20.57px;
    line-height: 24.42px;
  }
  .home_os_main_des {
    font-size: 22px;

    line-height: 32.48px;
    text-align: justify;
  }
  .home_os_graph_img {
    width: 100%;
    height: auto;
    margin: 10px auto;
  }
  .home_os_two_sec_wrapp_2_center_sec > img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  .home_os_two_sec_wrapp_2_center_sec > div > p {
    font-size: var(--font-18);
  }
  .home_os_glob_div {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0 0;
  }
  .home_os_globs {
    width: 100%;
    /* height: 333px; */
}
.glob2{
  height: 350px;
}
.glob-text{
  font-size: 90px;
  padding: 5px 4px 0 4px;

}
}
@media (min-width: 769px) and (max-width: 1024px) {
  .home_os_2{
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .home_os_two_sec_wrapp_2_2 {
    width: 100%;
    padding: 21px;
      gap: 10px;
  }
  .home_os_two_sec_wrapp_2_3 {
    width: 100%;
  }
  .home_os_graph_img {
    width: 100%;
    height: auto;
    margin: 10px auto;
  }
  .home_os_two_sec_wrapp_2_center_sec > img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  .home_os_glob_div {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 2rem 0;
  }
  .home_os_globs {
    width: 100%;
    /* height: 500px; */
}
.glob2{
  height: 500px;
}
.glob-text{
  font-size: 90px;
  padding: 5px 4px 0 4px;

}
  
}