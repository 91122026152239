.cs_result_main_wrapp{
    width: 100%;
    margin: auto;
    padding: 2rem 0;
}
.cs_result_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.cs_result_base_wrapp >h1{
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
    color: var(--color-white);
}
.cs_result_base_wrapp > h1 >span{
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;
    color: var(--color-white);
}
.cs_result_content_div{
    display: flex;
    margin-top: 4rem;
    gap: 2rem;
}
.cs_result_content_left{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.cs_result_circle_div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.half-circle{
    width: 125px;
    /* height: 125px; */
}
.cs_result_blk_div{
    background: #313132;
    border-radius: 25px;
    border: 1px solid #404040;
    padding: 1rem;
    /* height: 266px; */
}
.cs_result_content_right{
    width: 55%;
}
.cs_result_blk_div_right{
    background: #1A1A1B;
    border-radius: 25px;
    border: 1px solid #404040;
    padding: 1rem;
    height: 100%;
}
.cs_result_blk_div ul {
    list-style-type:disc; 
    padding-left: 20px;
}
.cs_result_blk_div ul li {
    margin-bottom: 4px;
}
.rs_intuitive {
    font-size: 24px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px ;
    width: 276px;
    min-height: 82px;
    border-radius: 10px;
    border: 2px solid #4ABFF0;
    transform: rotate(-12deg);
    position: relative;
    top: 92px;
    display: flex;
    left: 8rem;
    justify-content: center;
    align-items: center;
}
.rs_user {
    font-size: 24px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px 13px;
    height: 82px;
    border-radius: 10px;
    border: 2px solid #4ABFF0;
    width: 232px;
    transform: rotate(11deg);
    position: relative;
    left: 183px;
    top: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rs_easy {
    font-size: 24px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: center;
    color: var(--color-white);
    background: #FFFFFF1A;
    padding: 7px 13px;
    height: 81px;
    border-radius: 10px;
    border: 2px solid #4ABFF0;
    width: 194px;
    transform: rotate(-14deg);
    position: relative;
    left: 176px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cs_result_2nd_div{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}
.cs_result_full_circle{
    /* position: absolute; */
    width: 75%;
}
.cs_result_mob {
    position: absolute;
    /* height: 453px; */
    height: auto;
}
@media(max-width: 767px){
    .cs_result_base_wrapp{
        width: 90%;
    }
    .cs_result_content_div {
        flex-direction: column;
    }
    .cs_result_content_left {
        width: 100%;
    }
    .cs_result_content_right {
        width: 100%;
    }
    .cs_result_blk_div_right {
        height: 454px;
    }
    .cs_result_left_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .rs_intuitive{
        top:40px;
        left: 0;
    }
    .rs_user{
        left: 0;
        top: -9px;
    }
    .rs_easy{
        left: 0;
        top: -49px;
    }
    .cs_result_full_circle{
        /* width: 100%; */
    }
    .cs_result_mob{
    height: auto;
    /* min-width: 380px; */
    /* height: 165px; */
    /* object-fit: contain; */
    }
    .cs_result_base_wrapp >h1 {
        font-size: 35.66px;
    }
    .half-circle {
        width: 75px;
        /* height: 125px; */
    }
    .cs_result_2nd_div {

        padding: 3rem 0 0 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cs_result_base_wrapp{
        width: 98%;
    }
    .cs_result_content_div {
        flex-direction: column;
    }
    .cs_result_content_left {
        width: 100%;
    }
    .cs_result_content_right {
        width: 100%;
        max-width: 487px;
        margin: auto;

    }
    .cs_result_blk_div_right {
        height: 53vh;
    }
    .cs_result_left_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .rs_intuitive{
        top:40px;
        left: 0;
    }
    .rs_user{
        left: 0;
        top: -9px;
    }
    .rs_easy{
        left: 0;
        top: -49px;
    }
    .cs_result_full_circle{
        width: 100%;
    }
    .cs_result_mob{
    height: auto;
    width: 90%;
    }
    .cs_result_blk_div {

        max-width: 487px;
        margin: auto;
    }
    .cs_result_base_wrapp >h1 {
        margin-left: 5%;
    }
}