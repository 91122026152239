.about_main_wrapp{
    max-width: var(--max-width);
    margin: auto;
    width: 85%;
}
.abot_section1_mainn {
    width: 90%;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    max-width: var(--max-width);
    margin-top: 104px;
}
.about_section1_title {
    font-size: 75px;
    font-family: var(--font-aeoniktrial)!important;
    font-weight: 400;
    color: var(--color-white);
    line-height: 78px;
}
.asec_contect_btn_wrapp {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.5rem;
    margin-top: 8px;
    /* margin-right: 5%; */
    margin-bottom: 20px;
}
.asec_contect_btn_wrapp > p {
    font-family: var(--font-400);
    font-size: var(--font-18);
    line-height: 21.59px;
    text-align: center;
    color: var(--color-white);
}
.imgg{
    width: 100%;
    margin: auto;
    border-radius: 4.82px;
    height: 100%;
}
.imgg > img{
    border-radius: 8px;
    height: 100%;
}
.ab_para {
    display: flex;
    width: 100%;
    margin: auto;
    gap: 10rem;
    margin-top: 55px;
    margin-bottom: 55px;
    height: auto;
}
.ab_paraleft{
    width: 42%;
    font-family: var(--font-500);
    font-size: 38px;
    line-height: 45.59px;
    font-weight: 500;
}
.ab_pararight{
    line-height: 22.79px;
    width: 58%;
    font-family: var(--font-400);
    font-size: 19px;
    /* padding-top: 11px; */
    font-weight: 400;
}

@media (max-width: 768px){
    .abot_section1_mainn {
        width: 100%;
    }
    .about_section1_title {
        font-size: 42px;
        line-height: 70px;
        width: 414px;
    }
    .ab_para {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
        gap: 7%;
        margin-top: 55px;
        margin-bottom: 55px;
    }
    .ab_paraleft {
        width: 100%;
        font-size: 27px;
        line-height: 31px;
        text-align: justify;
    }
    .ab_pararight {
        width: 100%;
        font-size: 16px;
        margin-top: 20px;
        text-align: justify;
    }
    .about_main_wrapp {
        width: 90%;
    }

}

@media (min-width: 769px) and (max-width:1024px){
    .ab_para {
        gap: 3rem;
    }
    .about_main_wrapp {
        width: 90%;
    }
}