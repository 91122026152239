.contact_form_main_wrapp{
    max-width: var(--max-width);
    margin: 5rem auto 9rem auto;
}
.uploaded-file-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove-btn {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.contact_form_base_wrapp{

}
.contact_form_div{
    display: flex;
    width: 100%;
}
.contact_left{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
    gap: 2rem;
}
.contact_right{
    width: 55%;
}
.inqtyp_btn_con {
    font-family: var(--font-500);
    font-size: 27px;
    font-weight: 500;
    line-height: 30px;
    width: 290px;
    text-align: right;
    color: #9D9D9D;
    transition: color 0.5s; /* Add transition for color change */
  }
  
  .con_clicked {
    color: var(--color-white);
  }
  
.contact_hr{
    border: 2px solid;
    border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    border-image-slice: 1;
    width: 45%;
    transition: width 0.5s, opacity 0.5s; /* Add transitions for width and opacity */
    opacity: 0; /* Initially hidden */
}
  
  .con_hr_clicked {
    width: 45%; /* Make the line fully visible */
    opacity: 1; /* Make the line fully opaque */
  }
  

.contact-btn-group{
    display: flex;
    justify-content: center;
    align-items: center;    
    /* width: 200px; */
    gap: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-form-row {
    display: flex;
    gap: 1rem;
    height: 96px;
  }
  
  .contact_label_input_input{
    flex: 1;
    /* padding: 0.5rem; */
    font-family:var(--font-300);
    /* font-size: 19px; */
    font-weight: 400;
    border-radius: 2px;
    background: #27272A;
    /* height: 58px; */
  }
  /* .input-email{
    flex: 1;
    padding: 0.5rem;
    font-family:var(--font-300);
    font-size: 19px;
    font-weight: 400;
    border-radius: 2px;
    background: #27272A;
    height: 58px;
    border: 3.5px solid;
    border-image-source: linear-gradient(90deg, #000000 24.29%, #24D2DA 52.5%, #000000 78.55%);
    border-image-slice: 1;
  } */
  .contact_label_input > select{
    flex: 1;
    padding: 0.5rem;
    font-family:var(--font-300);
    font-size: 19px;
    font-weight: 400;
    border-radius: 2px;
    background: #27272A;
    height: 58px;
    color: #FFFFFF5E;
    width: 100%;
  }
  .contact_label_input_pro{
    flex: 1;
    padding: 0.5rem;
    font-family:var(--font-300);
    font-size: 19px;
    font-weight: 400;
    border-radius: 2px;
    background: #27272A;
    height: 108px;
  }
  .contact_label_input_msg{
    flex: 1;
    padding: 0.5rem;
    font-family:var(--font-300);
    font-size: 19px;
    font-weight: 400;
    border-radius: 2px;
    background: #27272A;
    height: 163px;
  }
  /* .contact_label_input_full {
    flex: 1;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 2px;
    background: #27272A;
  } */
  .contact_label_input_full_msg > textarea{
    flex: 1;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 2px;
    background: #27272A;
  }
  
  .full-width {
    width: 100%;
  }
  
  .message {
    height: 100px;
  }
  

  
  .button:hover {
    background-color: #0056b3;
  }
  .contact_label_input{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    }
    .contact_label_input >label{
    font-family:var(--font-300);
    font-size: 19px;
    font-weight: 400;
    line-height: 22.79px;
    text-align: left;

    }
    .contact_label_input_full{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        /* height: 10rem; */
    }
    .contact_label_input_full >label{
        font-family:var(--font-300);
        font-size: 19px;
        font-weight: 400;
        line-height: 22.79px;
        text-align: left;
    }
    .contact-form-group{
        height: auto;
    }
    .contact-form-group > button{
        /* border: 2px solid; */
        /* border-image-source: linear-gradient(180deg, #000000 95.39%, #24D2DA 100%); */
        font-family: var(--font-400);
        font-size: 19px;
        font-weight: 400;
        line-height: 22.79px;
        text-align: center;
        /* padding: 1rem; */
        /* border-image-slice: 1; */
        border-radius: 2px;
        background: #18181B;
        width: 100%;
        height: 76px;
    }
    .contact_label_input_full_msg{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        /* height: 16rem; */
    }
    .contact_label_input_full_msg >label{
        font-family:var(--font-300);
        font-size: 19px;
        font-weight: 400;
        line-height: 22.79px;
        text-align: left;
    }
    .phone-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    .phone-input-container select,
    .phone-input-container input {
        padding: 0.5rem;
        font-family:var(--font-300);
        font-size: 19px;
        font-weight: 400;
        border-radius: 2px;
        background: #27272A;
        height: 58px;
        color: #ffffff;
    }
      
      .phone-input-container select{
        width: 33%;
        cursor: pointer;
      }
      
      
      .phone-input-container input {
        width: 75%;
      }
      
      .phone-input-container select:last-child,
      .phone-input-container input:last-child {
        margin-right: 0;
      }
    
      .w-full{
        width: 100% !important
      }

      /* File input styling */
.input-file {
    display: none; /* Hide the actual file input */
  }
  
  .file-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #27272A;
    padding: 10px;
    border-radius: 2px;
    text-align: center;
    justify-content: center;
    height: 58px;

  }
  
  .file-label img.upload-icon {
    width: 90px;
  }
  .uploaded-file-name{
    height: 58px;
    background: #27272A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }

  /* modal */

.modal_screen{
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  color: white;
  border-radius: 5px;
  transition: opacity 0.5s;
  width: 100%;
  height: 100vh;
  margin: auto;
  z-index: 99999;
  /* backdrop-filter: blur(35px); */
  display: flex;
  justify-content: space-between;
background-color: black;
}
.modal_success {
  position: fixed;
  /* padding: 5rem; */
  transition: opacity 0.5s;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1280px;
  margin: auto;
  padding: 5rem 1rem 2rem 10%;

  width: 50%;
}
.modal_close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.body_img {
  width: 91px;
}
.body_h1 {
  font-size: 32px;
  font-family: var(--font-600);
  margin-bottom: 20px;
}
.loading_screen {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  /* transform: translate(-50%, -50%); */
  color: white;
  padding: 20px;
  border-radius: 5px;
  transition: opacity 0.5s;
  border-radius: 20px;
  width: 100%;
  height: 100vh;
  margin: auto;
  z-index: 99999;
  backdrop-filter: blur(35px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_close_1{
  display: flex;
  justify-content: end;
}
.loading_screen>img{
  width: 72px;
}
.body_img_div{
  display: flex;
    justify-content: center;
}

/* HTML: <div class="loader"></div> */
.loader-contact {
  width: 55px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #ffffff #0000;
  animation: l1 500ms infinite;
}
.searchable-dropdown {
  position: relative;
  height: 93%;
  width: 31%;
}

.selected-option {
  background: #27272A;
  padding: 8px;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  float: right;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #27272A;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1;
  width: 170px;
}

.dropdown-search {
  width: 100%!important;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
}

.dropdown-options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #040404;
}
.modal_success_content1>h1{
  font-size: 82px;
  color: #7474ff;
}
.modal_success_content1 >p{
  font-size: 32px;
  max-width: 552px;
  margin-top: 1rem;
}
.back-to-website-btn{
  margin-top: 3rem;
  border: 1px solid white;
  border-radius: 26px;
  padding: 14px 26px;
  font-size: 20px;
}
.modal_success_content2 >h2{
  font-size: 20px;
  color: gray;
}
.modal-btn-divv{
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}
.m-btn-link{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 20px;
}
.btn-circle{
  background: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-img-divv{
  object-fit: cover;
  z-index: -1;
  position: fixed;
  right: 0;
  height: 100vh;
  width: 50%;
  max-width: 41.666667%;
}
.modal-img-divv >img{
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;

}
.modal-img-divv::after{
  position: absolute;
  content: "";
  background: linear-gradient(90deg, #000000, rgba(1, 0, 0, 0));
  left: -1px;
  top: 0;
  height: 100%;
  width: 75%;
  z-index: 1;
}
@keyframes l1 {to{transform: rotate(.5turn)}}

    @media screen and (max-width: 767px) {
        .contact_form_main_wrapp{
            width: 90%;
            margin-top: 3rem;
        }
        .contact_form_div {
            flex-direction: column;
            gap: 2rem;
        }
        .form {
          gap: 1rem;
        }
        .contact_left {
            width: 100%;
            display: flex;
        
            justify-content: flex-start;
            /* align-items: center; */
        }
        .contact_right {
            width: 100%;
        }
        .contact_label_input {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            height: 96px;
        }
        .contact-form-row {
            display: flex;
            gap: 1rem;
            height: 209px;
            flex-direction: column;
        }
        .row-1-contact{
          height: 96px!important;
        }
        .phone-input-container select {
          width: 5rem;
      }
      .phone-input-container input {
        width: 85%;
      }
      .modal_screen {
        /* height: auto; */
    }
    .modal_success_content1>h1 {
      font-size: 60px;
    }
    .modal_success_content1 >p {
      font-size: 25px;
    }
    
    .contact_label_input_full {
      height: auto;
  }
    }

  @media (min-width: 768px) and (max-width:1024px){
    .contact_form_div {
        gap: 2rem;
    }
    .contact_left {
      width: 35%;
    }
    .contact_right {
      width: 65%;
    }
    .contact_form_main_wrapp {
      width: 90%;
    }
  }
  @media screen and (max-width: 989px) {
    .modal-img-divv {
      display: none;
    }
    .modal_success {
      width: 100%;
      padding: 5rem 1rem 2rem 1rem;

    }
  }

  /* .modal{
    position: fixed;
    max-width: 500px;
    margin: auto;
    border-radius: 11px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 43%), 0 6px 20px 0 rgb(0 0 0 / 32%);
  }
  .modal-content{
    background: white;
    width: 100%;
    height: 270px;
    border-radius: 11px;

  }

  #success_tic .page-body{
    max-width:300px;
    background-color:#FFFFFF;
    margin:10% auto;
  }
   #success_tic .page-body .head{
    text-align:center;
  }
  /* #success_tic .tic{
    font-size:186px;
  } */
  /* #success_tic .close{
        opacity: 1;
      position: absolute;
      right: 0px;
      font-size: 30px;
      padding: 3px 15px;
    margin-bottom: 10px;
    color: black;
  }
  #success_tic .checkmark-circle {
    width: 150px;
    height: 150px;
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
  .checkmark-circle .background {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: black;
    position: absolute;
  }
  #success_tic .checkmark-circle .checkmark {
    border-radius: 5px;
  }
  #success_tic .checkmark-circle .checkmark.draw:after {
    -webkit-animation-delay: 300ms;
    -moz-animation-delay: 300ms;
    animation-delay: 300ms;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-name: checkmark;
    -moz-animation-name: checkmark;
    animation-name: checkmark;
    -webkit-transform: scaleX(-1) rotate(135deg);
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -o-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  #success_tic .checkmark-circle .checkmark:after {
    opacity: 1;
    height: 75px;
    width: 37.5px;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    border-right: 15px solid #fff;
    border-top: 15px solid #fff;
    border-radius: 2.5px !important;
    content: '';
    left: 35px;
    top: 80px;
    position: absolute;
  }
  
  @-webkit-keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 37.5px;
      opacity: 1;
    }
    40% {
      height: 75px;
      width: 37.5px;
      opacity: 1;
    }
    100% {
      height: 75px;
      width: 37.5px;
      opacity: 1;
    }
  }
  @-moz-keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 37.5px;
      opacity: 1;
    }
    40% {
      height: 75px;
      width: 37.5px;
      opacity: 1;
    }
    100% {
      height: 75px;
      width: 37.5px;
      opacity: 1;
    }
  }
  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 37.5px;
      opacity: 1;
    }
    40% {
      height: 75px;
      width: 37.5px;
      opacity: 1;
    }
    100% {
      height: 75px;
      width: 37.5px;
      opacity: 1;
    }
  } */ 

.box00{
	width: 100px;
	height: 100px;
	border-radius: 50%;
}