.techinsight_main_wrapp {
    display: flex;
    max-width: var(--max-width);
    margin: auto;
    flex-direction: column;
    /* height: 112vh; */
}
.techinsight_img_wrapp {
    width: 100%;
    position: relative;
    margin: auto;
    margin-top: 46px;
    margin-bottom: 15px;
  }  
  .insight_banner_img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .techinsight_img_wrapp::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

  }
  .techinsight_text{
    font-size: 152px;
    color: transparent;
    font-family: var(--font-700);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    width: 100%;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    transform: translateY(-121px);
  }
  @media(max-width: 786px){
    .techinsight_text {
      font-size: 60px;
      line-height: 64px;
    transform: translateY(-44px);
    }  
    .techinsight_main_wrapp {
      height: auto;
      width: 90%;
  }
  }