.project_main{
    /* height: 354vh; */
    margin-top: 80px;
    /* margin-bottom: 50px; */
    height: auto;
    /* padding-bottom: 39rem; */
    padding-bottom: 18rem;
}
.sectn1{
    padding-top: 10rem;
        padding-bottom: 10rem;
        height: 495px;
        position: relative;
}
.font_h1{
    font-family: var(--font-aeoniktrial)!important;
    font-size: 75px;
    line-height: 78px;
    font-weight: 400;
}
.font_h2{
    font-family: var(--font-300);
    font-size: 27px;
    line-height: 32px;
}
.buttonn {
    position: absolute;
    /* top: 883px; */
    top: 562px;
    width: auto;
    height: 64px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 89px;
    background-color: #1F1F1F;
    font-size: 14px;
    font-family: var(--font-400);
    text-align: center;
    padding-left: .5rem;
    padding-right: 0px;
}
.buttonn_div{

}
.buttonn_button{
    width: 123px;
    height: 50px;
    text-align: center;
}
.buttonn_button_hover {
    width: 136px;
    height: 50px;
    text-align: center;
    background-color: white;
    color: black;
    border-radius: 89px;
}
.project_sli_new{
    position: relative;
    top: -20rem;
}
@media(max-width: 767px){
    .buttonn {
        width: 224px;
        height: auto;
        left: 50%;
        top: 640px;    
        transform: translateX(-50%);
        border-radius: 39px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .buttonn_button_hover {
        width: 141px;
        height: 35px;
        text-align: center;
        background-color: white;
        color: black;
        border-radius: 89px;
    }
    .project_main {
        height: 245vh;
        margin-top: 0px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .font_h1 {
        font-family: var(--font-400);
        font-size: 42px;
        line-height: 70px;
    }
    .font_h2 {
        font-family: var(--font-300);
        font-size: 21px;
        line-height: 29px;
    }
    .sectn1 {
        padding-top: 10rem;
        padding-bottom: 10rem;
        height: 817px;
        position: relative;
    }
    .project_sli_new {
        position: relative;
        top: -26rem;
    }
    .pro-slider-hei-wid{
        width: 429px !important;
        height: 284px !important;

        margin-left: 1.5rem!important;
        margin-right: 1.5rem!important;

    }
    .pro-slider-margin{
      
        margin-bottom: 2re!important;
    }
}
/* .buttonn {
    width: 224px;
    height: 206px;
    left: 50%;
    top: 797px;
 */
 @media (min-width: 768px) and (max-width: 1024px) {
    .buttonn {

        top: 516px;
        min-width: 514px;
    }
    .font_h1 {
        font-size: 50px;
    }
 }