.ser2_boost_main_wrapp{
    width: 100%;
    position: relative;
    z-index: 3;
    margin: auto;
    background-color: var(--color-white);
    padding: 0rem 0;
}
.ser2_boost_title_div{
    max-width: var(--max-width-md);
    margin: auto;
    text-align: center;
    width: 100%;
    padding-bottom: 0rem;
}
.ser2_boost_slider_div{
    max-width: var(--max-width);
    margin: auto;
}
.ser2_boost_title_div > h2{
    font-size: 36px;
    font-family: var(--font-inter);
    font-weight: 600;
    line-height: 43.57px;
    color: var(--color-black);
    padding-bottom: 1rem;
}
.ser2_boost_title_div >h3{
    font-size: 20px;
    font-family: var(--font-inter);
    font-weight: 500;
    line-height: 24px;
    color: #000000A8;
}
.ser2_boost_slider_div > h2{
    font-size: 32px;
    font-family: var(--font-inter);
    font-weight: 600;
    line-height: 38px;
    color: var(--color-black);
    padding-bottom: 4rem;
    padding-left: 1rem;
}
.ser2_boost_slide{
    background: #F5F5F5;
    box-shadow: 0px 0px 5.75px 0px #00000014;
    border-radius: 7.87px;
    width: 100% !important;
    height: 340px;
}
.ser2_boost_slide >img{
width: 100%;
border-radius: 8px;
}
.ser2_boost_slide_content {
    padding: 1rem;
    position: relative;
}
.ser2_boost_slide_content > h2{
    font-size: 19px;
    font-family: var(--font-poppins);
    font-weight: 700;
    line-height: 29px;
    color: var(--color-black);
    padding-top: 1rem;
}
.ser2_boost_slide_content > p{
    font-size: 12px;
    font-family: var(--font-poppins);
    font-weight: 500;
    line-height: 17.72px;
    color:#00000080;
    padding-top: 1rem;
    width: 90%;
}
.ser2_boost_slider.slick-initialized .slick-slide {
    display: block;
    padding: 1rem!important;
}
.btn_boost {
    width: 41px;
    transition-duration: 500ms;
    height: 41px;
    border-radius: 80px;
    border: 2px solid white;
    position: absolute;
    right: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1.04px solid;
    border-image-source: radial-gradient(23.58% 23.58% at 50.31% -3.77%, #5CB8EC 0%, rgba(255, 255, 255, 0.5) 100%);
    border-image-slice: 1; */

}
.btn_boost >img{
    width: 14px;
    transform: rotate(45deg);
}

@media(max-width: 768px){
.ser2_boost_title_div {
    width: 90%;
    
    padding-bottom: 2rem;
}
.ser2_boost_slider_div {
    width: 90%;
}
.ser2_boost_slider_div > h2 {
    padding-bottom: 2rem;
}
.ser2_boost_slider .slick-next {
    right: 0px;
    display: none !important;
}
}