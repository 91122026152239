.cs_color_main_wrapp{
    width: 100%;
    margin: auto;
    padding: 5rem 0;
}
.cs_color_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
}
.cs_color_base_wrapp >h1{
    color: var(--color-white);
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
}
.cs_color_base_wrapp >h1>span{
    color: var(--color-white);
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;
}
.cs_color_grid_wrapp {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 10px;
    padding: 10px;
    z-index: 111;
    margin-top: 0px;
    /* margin-bottom: 70px; */
    /* position: absolute; */
    width: 100%;
    /* min-width: 400px; */
    min-height:513px;
    left: 50%;
    top: 34%;
    /* transform: translate(-50%, 20%); */
    border-radius: 8px;
    margin-top: 1.5rem;
    row-gap: 3rem;
}
.cs_grid_1 {
    grid-column: 1 / 2;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #02245B;

}
.cs_grid_2 {
    grid-column: 2 / 3;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #FF5E14;

}
.cs_grid_3 {
    grid-column: 3 / 4;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #1EC199;

}
.cs_grid_4 {
    grid-column: 4 / 5;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #FFFFFF;

}
.cs_grid_5 {
    grid-column: 5 / 6;
    grid-row: 1 / 4;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #444444;

}
.cs_grid_6 {
    grid-column: 5 / 6;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #89807A;

}
/* for rararez */
.cs_grid_1_rar {
    grid-column: 1 / 2;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #E51A4B;

}
.cs_grid_2_rar {
    grid-column: 2 / 3;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #FF004C;
}
.cs_grid_3_rar {
    grid-column: 3 / 4;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #201D23;

}
.cs_grid_4_rar {
    grid-column: 4 / 5;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #3C3247;
}
.cs_grid_5_rar {
    grid-column: 5 / 6;
    grid-row: 1 / 4;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #FFFFFF;
}
.cs_grid_6_rar {
    grid-column: 5 / 6;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #0C0C0C;
}

/* for Dustit */
.cs_color_grid_wrapp_dust {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 10px;
    padding: 10px;
    z-index: 111;
    margin-top: 0px;
    width: 100%;
    min-height: 513px;
    border-radius: 8px;
    row-gap: 3rem;
    text-align: center;
    max-width: 775px;
    margin: auto;
}
.cs_grid_1_dust {
    grid-column: 1 / 2;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #00A1A6;

}
.cs_grid_2_dust {
    grid-column: 2 / 3;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #FFFFFF;
}
.cs_grid_3_dust {
    grid-column: 3 / 4;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #131313;

}
/* for Beurer */
.cs_color_grid_wrapp_beurer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 10px;
    padding: 10px;
    z-index: 111;
    margin-top: 0px;
    width: 100%;
    min-height: 513px;
    border-radius: 8px;
    row-gap: 3rem;
    text-align: center;
    max-width: 1026px;
    margin: auto;
}
.cs_grid_1_beurer {
    grid-column: 1 / 2;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #C50050;

}
.cs_grid_2_beurer {
    grid-column: 2 / 3;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #FFFFFF;
}
.cs_grid_3_beurer {
    grid-column: 3 / 4;
    grid-row: 4 / 7;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #E4E4E4;

}
.cs_grid_4_beurer {
    grid-column: 4 / 5;
    grid-row: 2 / 6;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: #006975;
}

@media(max-width: 767px){
    .cs_color_grid_wrapp {
        min-height: 195px;
        row-gap: 10px;
        padding: 0;
    }
    .cs_color_grid_wrapp_dust{
        min-height: 195px;
        row-gap: 10px;
        padding: 0;
    }
    .cs_color_grid_wrapp_beurer{
        min-height: 195px;
        row-gap: 10px;
        padding: 0;
    }
    .cs_grid_1 ,.cs_grid_2,.cs_grid_3,.cs_grid_4,.cs_grid_5,.cs_grid_6{
        border-radius: 8px;
    }
    .cs_grid_1_rar ,
    .cs_grid_2_rar,
    .cs_grid_3_rar,
    .cs_grid_4_rar,
    .cs_grid_5_rar,
    .cs_grid_6_rar{
        border-radius: 8px;
    }
    .cs_color_base_wrapp {
        width: 90%;
    }
    .cs_grid_1_dust ,
    .cs_grid_2_dust,
    .cs_grid_3_dust
    {
        border-radius: 8px;
    }
    .cs_color_base_wrapp >h1 {
        color: var(--color-white);
        font-size: 35.66px;
    }
    .cs_grid_1_beurer,
    .cs_grid_2_beurer,
    .cs_grid_3_beurer,
    .cs_grid_4_beurer{
        border-radius: 8px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .cs_color_base_wrapp {
        width: 90%;
    }
    .cs_color_grid_wrapp {
        min-height: 358px;
    }
}