.cs_tropo_main_wrapp {
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.cs_tropo_back_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.cs_tropo_base_wrapp {
    position: relative;
    margin: auto;
    padding: 5rem 0 5rem 0;
    border-radius: 5px;
    text-align: -webkit-center;
    max-width: var(--max-width);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    justify-content: center;

}.cs_tropo_title_div{
    display: flex;
    justify-content: space-between;
}
.cs_tropo_title_div >h1{
    text-align: left;
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
    color: var(--color-white);

}.cs_tropo_title_div >h1>span{
    color: var(--color-white);
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;
}
.cs_tropo_title_div > div >p{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: end;
    color: var(--color-white);
}
.cs_tropo_title_div >div{
    display: flex;
    flex-direction: column;
    gap: 9px;

}
.cs_tropo_content_div1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cs_tropo_content_div1 > div{
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.cs_tropo_content_div1> div>h2{
    font-family:var(--font-rubik)!important;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.59px;
    text-align: left;   
}
.cs_tropo_content_div1> div>p{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    font-family:var(--font-rubik)!important;;
    text-align: left;  
}
.rubik-font{
    font-family:var(--font-rubik)!important;;
    font-size: 150px;
    font-weight: 400;
    line-height: 177.75px;
    
}
.cs_tropo_content_div2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cs_tropo_opensans{
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.cs_tropo_opensans >h2{
    text-align: left;
    font-family: var(--font-opensans)!important;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.59px;
   
}
.cs_tropo_opensans >p{
    font-family: var(--font-opensans)!important;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;  
}
.cs_tropo_opensans >h1{
    font-family: var(--font-opensans)!important;
    font-size: 150px;
    font-weight: 400;
    line-height: 204.27px;
}

/* rarerez tropo */
.cs_tropo_main_wrapp_rar {
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.inter-font{
    font-family:var(--font-inter)!important;;
    font-size: 150px;
    font-weight: 400;
    line-height: 177.75px;  
}
.cs_tropo_gotham {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.cs_tropo_gotham >p {
    font-family: var(--font-gotham) !important;
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 19.07px;
    text-align: left;
}
.cs_tropo_back_img_tropo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

/* dustit tropo */
.caros-font{
    font-family:var(--font-caros)!important;
    font-size: 150px;
    font-weight: 700;
    line-height: 177.75px; 
}
.cs_extra_dust{
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.cs_tropo_dusta{
    font-family:var(--font-caros)!important;
    font-size: 213.24px;
    font-weight: 700;
    line-height: 165.72px;
    text-align: end;    
}
.cs_tropo_caros {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.cs_tropo_caros >p {
    font-family: var(--font-caros) !important;
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 19.07px;
    text-align: left;
}
.cs_tropo_content_div2_dust{
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.cs_tropo_main_wrapp_dust {
    width: 100%;
    height: 100vh;
    margin: auto;
    position: relative;
}

/* beurer tropo */
.cs_tropo_inter {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.cs_tropo_inter >p {
    font-family: var(--font-inter) !important;
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 19.07px;
    text-align: left;
}
@media(max-width: 480px){ 
    .cs_tropo_back_img {
        height: 100%;
    }
    .cs_tropo_base_wrapp {
    width: 90%;
    margin: auto;
    gap: 2rem;
    }
    .cs_tropo_title_div {
        flex-direction: column;
    }
    .cs_tropo_content_div1 {
        align-items: unset;
        flex-direction: column;
    }
    .cs_tropo_content_div2 {

        align-items: unset;
        flex-direction: column;
    }
    .rubik-font{
        text-align: end;
        font-size: 50px;
        line-height: 89px;
    }
    .inter-font{
        text-align: end;
        font-size: 50px;
        line-height: 89px;
    }
    .caros-font{
        text-align: end;
        font-size: 50px;
        line-height: 89px;
    }
    .cs_tropo_opensans >h1 {
        font-size: 50px;
        text-align: end;
        line-height: 89px;
    }
    .cs_tropo_dusta{
        font-family:var(--font-caros)!important;
        font-size: 50px;
        font-weight: 700;
        line-height: 70px;
        text-align: end;    
    }
    .cs_tropo_content_div2_dust {
        justify-content: space-between;
        align-items: baseline;
        flex-direction: column;
    }
    .cs_tropo_dusta_div{
        width: 100%;
    }
    .cs_tropo_main_wrapp_dust {
        height: 62vh;
    }
    .cs_tropo_title_div >h1 {
        font-size: 35.66px;
    }
}

@media (min-width: 481px) and (max-width: 767px){
  
    .cs_tropo_base_wrapp {
    width: 90%;
    margin: auto;
    gap: 2rem;
    }
    .cs_tropo_title_div {
        flex-direction: column;
    }
    .cs_tropo_content_div1 {
        align-items: unset;
        flex-direction: column;
    }
    .cs_tropo_content_div2 {

        align-items: unset;
        flex-direction: column;
    }
    .rubik-font{
        text-align: end;
        font-size: 50px;
        line-height: normal;
    }
    .inter-font{
        text-align: end;
        font-size: 50px;
        line-height: normal;
    }
    .cs_tropo_opensans >h1 {
        font-size: 50px;
        text-align: end;
        line-height: normal;
    }
    .caros-font{
        text-align: end;
        font-size: 50px;
        line-height: normal;
    }
    .cs_tropo_dusta{
        font-family:var(--font-caros)!important;
        font-size: 50px;
        font-weight: 700;
        line-height: 70px;
        /* text-align: center;     */
    }
    .cs_tropo_content_div2_dust {
        justify-content: space-between;
        align-items: baseline;
        flex-direction: column;
    }
    .cs_tropo_dusta_div{
        width: 100%;
    }
    .cs_tropo_main_wrapp_dust {
        width: 100%;
        height: 62vh;
        margin: auto;
        position: relative;
    }
    .cs_tropo_title_div >h1 {
        font-size: 35.66px;
    }
 }

 @media (min-width: 768px) and (max-width: 1024px) {
    .rubik-font {
        font-size: 75px;
        line-height: normal;
    }
    .inter-font {
        font-size: 75px;
        line-height: normal;
    }
    .caros-font{
        text-align: end;
        font-size: 105px;
        line-height: 177px;
    }
    .cs_tropo_opensans >h1 {
        font-size: 75px;
        line-height: normal;
    }
    .cs_tropo_main_wrapp_dust {
        width: 100%;
        height: 84vh;
        margin: auto;
        position: relative;
    }
    .cs_tropo_dusta {
        font-family: var(--font-caros) !important;
        font-size: 100px;
        font-weight: 700;
        line-height: 110px;
        text-align: end;
    }
    .cs_tropo_base_wrapp {
        width: 90%;

    }
 }