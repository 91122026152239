
:root {
    --color-white: #fff;
    --brand-color-g: #348e80;
    --brand-color: #df8625;
  }
  .CS_roadmap_main_wrapp{
    width: 100%;
    padding: 5rem 0;
    background: var(--color-white);
  }
  .CS_roadmap_base_wrapp{
    max-width: var(--max-width);
    margin: auto;
  }
  .CS_roadmap_base_wrapp >h1{
    font-size: 49.66px;
    font-weight: 700;
    line-height: 60.09px;
    color: var(--color-black);
    margin-left: 4%;
  }
  .CS_roadmap_base_wrapp >h1>span{
    font-size: 49.66px;
    font-style: italic;
    font-weight: 400;
    line-height: 60.09px;

  }
  .ps-timeline-sec {
    position: relative;
    background: var(--color-white);
  }
  
  .ps-timeline-sec .container {
    position: relative;
  }
  
 
  
  .ps-timeline-sec .container ol:before {
    left: 8px;
    top: 49.5%;
  }
  
  .ps-timeline-sec .container ol:after {
    right: 8px;
    top: 49.5%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 204px 0;
    padding: 0;
    border-top: 5px solid #56B5FB;
    list-style: none;
    position: relative;
  }
  
  
  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 16.6%;
    padding-top: 19px;
    position: relative;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li span {
  
    margin-left: -25px;
    background: var(--color-white);


    box-shadow: 0 0 0 0px var(--color-white);
    text-align: center;
    line-height: 50px;
    color: var(--brand-color);
    font-size: 2em;
    position: absolute;
    top: -25px;
    left: 50%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before,
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: '';
    color: #56B5FB;
    width: 2px;
    height: 113px;
    background: linear-gradient(180deg, #54BAFB 0%, #548BE4 100%);
    position: absolute;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    top: -92px;
    left: 50%;
  }
  

  
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    bottom: 88px;
    left: 44%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    bottom: -134px;
    left: 50%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    top: 90px;
    left: 44%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 146px;
    width: 100%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img,
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    margin-top: 98px;
    width: 100%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 48px;
    left: 25%;
  }
  
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 0px;
    left: 22%;
  }
  

  .cs_circle_text{
    width: max-content; 
    box-shadow: 0px 0px 4px 0px #00000014;
    background: #F6F8FB;
    height: 58px;
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
  }
  .cs_circle_text >div{
    height: 37px;
    width: 37px;
    background: var(--color-white);
    border-radius: 37px;
  }
  .cs_circle_text>h2{
font-size: 20px;
font-weight: 600;
line-height: 24.2px;
text-align: center;
color: var(--color-black);

  }
  .cs_circle_text_dark{
    width: max-content; 
    box-shadow: 0px 0px 4px 0px #00000014;
    background: #0D0D0E;
    height: 58px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
  }
  .cs_circle_text_dark >h2{
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
    color: var(--color-white);
  }
  .cs_circle_text_dark >div{
    background: #313132;
    height: 37px;
    width: 37px;
    border-radius: 37px;
  }
  .cs_circle_bottom_light{
    height: 49px;
    width: 63px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F6F8FB;
    box-shadow: 0px 1px 4px 0px #0000000D;

  }
  .cs_circle_bottom_clr{
    height: 49px;
    width: 63px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(122.45deg, #53B9FA 34.58%, #5388E2 97.4%, #929395 97.41%);
    box-shadow: 0px 1px 4px 0px #0000000D;


  }
  .cs_circle_bottom_light >p,.cs_circle_bottom_clr{
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    color: var(--color-black);
  }
  /* Responsive Styles */
  
  @media screen and (max-width: 768px) {
    .CS_roadmap_base_wrapp{
        width: 90%;
    }
    .CS_roadmap_base_wrapp >h1{
        margin-left: 0;
    }
    .ps-timeline-sec .container ol:before {
      top: 130px !important;
      left: 36px !important;
    }
  
    .ps-timeline-sec .container ol:after {
      top: inherit !important;
      left: 36px;
    }
  
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 47px 0 !important;
      border-left: 4px solid #56B5FB;
      padding-left: 0 !important;
      padding-top: 120px !important;
      border-top: 0 !important;
      margin-left: 25px !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 220px;
      float: none !important;
      width: inherit !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
      width: 70px;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li:last-child {
      margin: 0;
      bottom: 0 !important;
      height: 120px;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
        bottom: 14px !important;
        width: 40% !important;
        margin-left: 35px !important;
        margin-top: 0 !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
      width: 100%;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
      margin-bottom: 0 !important;
      top: -8px;
      width: 50% !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li span {
      left: 0 !important;
    }
  
    /* .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before,
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after,
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before,
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
      content: none !important;
    } */

    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
        top: 10px;
        left: 22px;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before, .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
        width: 55px;
        height: 2px;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
        bottom: -40px;
        left: 21px;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
      position: absolute !important;
      bottom: 150px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      margin: 0 auto !important;
      width: 80% !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
      position: absolute !important;
      bottom: 115px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li p {
   
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -8px;
      left: 18%;
    }
  
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -44px;
      left: 18%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .cs_circle_text>h2 {
      font-size: 13px;
    }
    .cs_circle_text_dark >h2 {
      font-size: 13px;
  }
  .cs_circle_text >div {
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }
  .cs_circle_text_dark >div {
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }
  .cs_circle_text {
    height: 39px;
    padding: 7px;
  }
  .cs_circle_text_dark {
    height: 39px;
    padding: 7px;
  }
  }