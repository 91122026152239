.cs_is_main_wrapp{
    width: 100%;
    margin: auto;
}
.cs_is_main_wrapp > img{
    width: 100%;
    height: 100%;
}
@media(max-width: 768px){
.cs_is_main_wrapp > img {
    width: 100%;
    /* height: 350px; */

}
}