.casestudy {
    position: relative;
    z-index: 3;
    background-color: white;
    padding-top: 95px;
}

.Casestudy_main {
    width: 93%;
    text-align: center;
    max-width: var(--max-width);
    margin: auto;
    /* margin-bottom: 95px; */
}

.Casestudy_main>h2 {

    font-size: 36px;
    color: black;
    font-family: var(--font-400);
}

.Casestudy_main>h1 {
    color: black;
    font-size: 25px;
    font-family: var(--font-400);
    margin-top: 10px;
    margin-bottom: 82px;
}

.cardsection_main {
    display: flex;
    gap: 2%;
    padding-bottom: 78px;
}

.casecard1 {
    width: 25%;
    height: 350px;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    
}

.casecard1>img {
    width: 100%;
    /* border-radius: 10px; */
    height: 82%;
    object-fit: cover;
    padding-top: 29.5%;
}

.cardtop {
    background: linear-gradient(109.56deg, rgb(41 34 54) 0%, rgba(0, 0, 0, 0.5) 126.32%);
    position: absolute;
    width: 100%;
    padding: 24px 19px;
    text-align: start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.cardtop>h6 {
    color: #fff;
    font-size: 10px;
    font-family: var(--font-400);
    line-height: 17px;

}

.cardtop>h3 {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-600);


}

.cardbottom {
    background: linear-gradient(109.56deg, rgb(39 32 53 / 81%) 0%, rgba(0, 0, 0, 0.5) 126.32%);
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 24px 19px;
    text-align: start;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.right_case
{
    max-width: 25px;
    position: absolute;
    right: 10px;
    bottom: 25px;
}
.cardbottom>h6 {
    color: #fff;
    font-size: 14px;
    font-family: var(--font-400);
    position: relative;
    /* top: 5px; */
}

.cardbottom>h3 {
    font-size: 20px;
    font-family: var(--font-600);
}

/* Casestudy.css */

.casestudy {
    /* Your existing styles */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 80%;
    text-align: center;
}

.modal-content img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #555;
}


@media(max-width: 768px) {
    .casestudy {
        padding-top: 73px;
    }
    .Casestudy_main>h1 {
        font-size: 25px;
        margin-bottom: 64px;
        line-height: 38px;
    }


    .cardsection_main {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .casecard1 {
        width: 350px;
        height: 414px;
        margin: auto;
    }
    .casecard1>img {
        width: 100%;
        /* border-radius: 10px; */
        height: 85%;
        object-fit: cover;
        padding-top: 88px;
    }
    .Casestudy_main>h2 {
        font-size: 32px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .casecard1 {
        max-width: 335px;
        width: 100%;
    }
    .cardsection_main {
        gap: 2%;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 1rem;
        justify-content: center;
    }
    .casecard1>img {
        padding-top: 25.7%;
    }
}